import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import { DateRange } from "react-date-range";
import { DateRangeOutlined } from "@mui/icons-material";
import Loader from "../../Components/Loader";
import instance from "../../Utils/api";
import EmployeeChart from "./employeeChart";
import { toast } from "react-toastify";

export default function Charts({ ...props }) {
  const [reviews, setReviews] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [employeeValue, setEmployeeValue] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectedDate, SetselectedDate] = useState([
    {
      fromDate: null,
      toDate: null,
    },
  ]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let chartData = reviews
    .sort((a, b) => a.timestamp - b.timestamp)
    .map((item) => {
      return { date: item.timestamp, value: item.rating };
    });

  const getFilterFeedbackData = async () => {
    const fromDate =
      selectedDate &&
      selectedDate[0].fromDate &&
      selectedDate[0].fromDate.getTime();
    const toDate =
      selectedDate &&
      selectedDate[0].toDate &&
      selectedDate[0].toDate.getTime();
    setShowLoader(true);
    let queryString = [];
    if (fromDate !== undefined && fromDate !== null && toDate !== undefined && toDate !== null) {
      queryString.push("fromDate=" + fromDate);
      queryString.push("toDate=" + toDate);
    }
    if(employeeValue !== null) {
      queryString.push("user=" + employeeValue.value);
      let urlParams =
        "/submission" +
        (queryString.length > 0 ? "?" + queryString.join("&") : "");
    await instance({
      method: "GET",
      url: `${urlParams}`,
      validateStatus: () => true,
    }).then((data) => {
      if (data.status === "SUCCESS!") {
        setShowLoader(false);
        setOpen(true);
        if (data.result.length > 0) {
          setReviews(data.result);
        } else {
          setReviews([]);
        }
        setShowLoader(false);
      } else {
        setShowLoader(false);
        alert(data.error.message || "API Error!");
      }
    });
    } else {
      toast.error("Please select associate")
      setShowLoader(false);
    }
  };

  const applyFilter = () => {
    setOpenDatePicker(false);
    getFilterFeedbackData();
  };

  const getEmployees = async () => {
    await instance({
      method: "GET",
      url: "user/getEmployees?role=3",
      validateStatus: () => true,
    }).then((data) => {
      if (data.status === "SUCCESS!") {
        var list = [];
        if (data.result.length > 0) {
          data.result.map(async (item) => {
            list.push({
              label:
                (item.id ? item.id + " -" : "") +
                " " +
                item.firstName +
                " " +
                (item.lastName ? item.lastName : ""),
              value: item._id,
            });
          });
        }
        if (list.length > 0) {
          setEmployeeList(list);
        }
      } else {
        alert(data.error.message);
      }
    });
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenDatePicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Grid container component="main">
      <Grid item xs={12} sm={12} md={12}>
        <Box
          sx={{
            my: 4,
            mx: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            ref={wrapperRef}
            style={{ height: "100%", maxWidth: "430px", width: "100%" }}
          >
            <Box sx={{ mt: 1, width: "100%" }}>
              <div className="date-flex">
                <div
                  className="date-box"
                  onClick={() => setOpenDatePicker(!openDatePicker)}
                  style={{
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div className="to-from-label">
                    Select Range &nbsp;
                    <DateRangeOutlined />
                  </div>
                  {(selectedDate[0].fromDate || selectedDate[0].toDate) && (
                    <div className="to-from-label" style={{ color: "black" }}>
                      Selected Range:&nbsp;
                      {selectedDate[0].fromDate &&
                        moment(selectedDate[0].fromDate).format("DD/MM/YYYY")}
                      <sapn>&nbsp;-&nbsp;</sapn>
                      {selectedDate[0].toDate &&
                        moment(selectedDate[0].toDate).format("DD/MM/YYYY")}
                    </div>
                  )}
                </div>
                {selectedDate[0].toDate && selectedDate[0].fromDate && (
                  <div
                    style={{
                      padding: "0px 10px",
                      color: "red",
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      SetselectedDate([{}]);
                      setState([
                        {
                          startDate: new Date(),
                          endDate: new Date(),
                          key: "selection",
                        },
                      ]);
                      setOpenDatePicker(false);
                    }}
                  >
                    Clear
                  </div>
                )}
              </div>
              {openDatePicker && (
                <div className="date">
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => {
                      setState([item.selection]);
                      SetselectedDate([
                        {
                          fromDate: item.selection.startDate,
                          toDate: item.selection.endDate,
                        },
                      ]);
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                    rangeColors={["blue", "pink", "red"]}
                  />
                </div>
              )}

              <div
                style={{ padding: "10px 0px" }}
                onClick={() => setOpenDatePicker(false)}
              >
                <Autocomplete
                  value={employeeValue}
                  onChange={(event, newValue) => {
                    setEmployeeValue(newValue);
                  }}
                  id="controllable-states-demo"
                  options={employeeList}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose Associate"
                      InputLabelProps={{
                        style: { fontSize: 14, color: "#575757" },
                      }}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px 0px",
                }}
              >
                <Button
                  variant="contained"
                  className="button"
                  onClick={() => applyFilter()}
                >
                  Show Chart
                </Button>
              </div>
            </Box>
          </div>
        </Box>
        <Dialog
          maxWidth="md"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          fullScreen={fullScreen}
          sx={{
            margin: "10px",
          }}
          fullWidth={true}
        >
          <div
            onClick={() => setOpen(false)}
            style={{
              justifyContent: "flex-end",
              alignItems: "flex-end",
              flexDirection: "row",
              padding: "5px",
              display: "flex",
              cursor: "pointer",
              color: "red",
              fontWeight: "600",
            }}
          >
            Close
          </div>
          <div style={{ padding: "0px 15px" }}>
            {reviews.length > 0 && (
              <div>
                <EmployeeChart chartData={chartData} />
              </div>
            )}
            {reviews.length === 0 && (
              <div style={{ textAlign: "center" }}>
                {" "}
                <img src={require("../../assets/Images/notFound.gif")} alt="" />
              </div>
            )}
          </div>
        </Dialog>
        <Loader open={showLoader} />
      </Grid>
    </Grid>
  );
}
