import {
  Box,
  Dialog,
  InputAdornment,
  Grid,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import instance from "../../Utils/api";
import CreateEmployeeClient from "./create-employee-client";
import Loader from "../../Components/Loader";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function Clients() {
  const [showLoader, setShowLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState();
  const [getClientList, setClientList] = useState([]);
  const location = window.location.pathname.split("/");
  const [open, setOpen] = useState(false);
  const [modalValue, setModalValue] = useState({
    label: "",
    userType: "",
  });
  const [roleValue, setRole] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const getClientApi = async () => {
    var role = "";
    if (location[1] === "employees") {
      role = "3";
    } else if (location[1] === "clients") {
      role = "2";
    }
    if (role) {
      setShowLoader(true);
      setRole(role);
      await instance({
        method: "GET",
        url: `user/getEmployees?role=${role}`,
        validateStatus: () => true,
      }).then((data) => {
        if (data.status === "SUCCESS!") {
          setShowLoader(false);
          if (data.result.length > 0) {
            setClientList(data.result);
            setSearchData(data.result);
          }
        } else {
          toast.error(data.error.message || "Api error");
          setShowLoader(false);
        }
      });
    }
  };

  const SearchList = (e) => {
    setSearchValue(e);
    const original = searchData;
    if (e.length > 0) {
      const filterdata = original.filter((item) => {
        const query = e.toLowerCase();
        return (
          (item.firstName && item.firstName.toLowerCase().includes(query)) ||
          (item.lastName && item.lastName.toLowerCase().includes(query)) ||
          (item.email && item.email.toLowerCase().includes(query)) ||
          (item.id && String(item.id).includes(String(e)))
        );
      });
      setClientList(filterdata);
    } else {
      setClientList(original);
    }
  };

  const closeSearch = () => {
    setSearchValue("");
    setClientList(searchData);
  };

  useEffect(() => {
    getClientApi();
  }, []);

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
    setSelectedUser([]);
    if (roleValue === "3") {
      setModalValue({
        label: "Create Associate",
        userType: "Associate",
      });
    } else {
      setModalValue({
        label: "Create Customer",
        userType: "Customer",
      });
    }
  };

  const edit = (value) => {
    setOpen(true);
    setSelectedUser(value);
    if (roleValue === "3") {
      setModalValue({
        label: "Edit Associate",
        userType: "Associate",
      });
    } else {
      setModalValue({
        label: "Edit Customer",
        userType: "Customer",
      });
    }
  };

  const toggleSortOrder = (column) => {
    let newSortOrder;
    if (sortColumn === column) {
      switch (sortOrder) {
        case "original":
          newSortOrder = "asc";
          break;
        case "asc":
          newSortOrder = "desc";
          break;
        case "desc":
          newSortOrder = "original";
          break;
        default:
          newSortOrder = "original";
      }
    } else {
      newSortOrder = "asc";
      setSortColumn(column);
    }
    setSortOrder(newSortOrder);
  };

  
  const sortedData = [...getClientList].sort((a, b) => {
    switch (sortColumn) {
      case "firstName":
        return sortOrder === "asc"
          ? a.firstName.localeCompare(b.firstName)
          : sortOrder === "desc"
          ? b.firstName.localeCompare(a.firstName)
          : 0;
      case "lastName":
        return sortOrder === "asc"
          ? (a.lastName || "").localeCompare(b.lastName || "")
          : sortOrder === "desc"
          ? (b.lastName || "").localeCompare(a.lastName || "")
          : 0;
      case "email":
        return sortOrder === "asc"
          ? a.email.localeCompare(b.email)
          : sortOrder === "desc"
          ? b.email.localeCompare(a.email)
          : 0;
      case "id":
        return sortOrder === "asc"
          ? (a.id || 0) - (b.id || 0)
          : sortOrder === "desc"
          ? (b.id || 0) - (a.id || 0)
          : 0;
      default:
        return 0;
    }
  });

  return (
    <Box className="main">
      <Grid xs={12} sm={12} item md={11} className="mg-auto">
        <Paper style={{ height: "40px", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              background: "#F5F5F5",
              padding: "10px 10px 10px 5px",
              fontWeight: "600",
              justifyContent: "space-between",
            }}
          >
            {roleValue === "3" ? "Associates" : "Customers"}
          </Box>
        </Paper>
        <Paper style={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                px: 2,
                justifyContent: "space-between",
              }}
            >
              <Grid item md={8}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    id="search-bar"
                    className="text"
                    onInput={(e) => {
                      SearchList(e.target.value);
                    }}
                    label="Search by Name"
                    variant="outlined"
                    placeholder="Search..."
                    size="small"
                    value={searchValue}
                    style={{
                      width: "100%",
                      margin: "10px 0px",
                      padding: "0px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          onClick={() => closeSearch()}
                          style={{ cursor: "pointer" }}
                          position="start"
                        >
                          <CloseIcon className="close-icon" />
                        </InputAdornment>
                      ),
                      style: {
                        padding: "0px",
                      },
                    }}
                    InputLabelProps={{
                      style: { fontSize: 14, color: "#575757" },
                    }}
                  />
                </div>
              </Grid>
              <Button
                variant="contained"
                className="button create-btn"
                sx={{ mt: 3, mb: 2 }}
                // style={{ margin: "0px", height: "40px" }}
                onClick={() => {
                  handleClick();
                }}
              >
                {roleValue === "3" ? "Create Associates" : "Create Customer"}
              </Button>
            </Box>
            <div style={{ overflow: "auto" }}>
              <table>
                <thead>
                  <tr>
                    <th
                      className="pro-th-pd"
                      onClick={() => toggleSortOrder("firstName")}
                    >
                      <div style={{ display: "flex", alignItems: "center",cursor:'pointer' }}>
                        <div>First Name</div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {sortColumn === "firstName" &&
                            sortOrder === "asc" && <KeyboardArrowDownIcon />}
                          {sortColumn === "firstName" &&
                            sortOrder === "desc" && <KeyboardArrowUpIcon />}
                          {(sortColumn !== "firstName" ||
                            sortOrder === "original") && (
                            <KeyboardArrowRightIcon />
                          )}
                        </div>
                      </div>
                    </th>

                    <th
                      className="pro-th-pd"
                      onClick={() => toggleSortOrder("lastName")}
                    >
                      <div style={{ display: "flex", alignItems: "center",cursor:'pointer' }}>
                        <div>Last Name</div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {sortColumn === "lastName" &&
                            sortOrder === "asc" && <KeyboardArrowDownIcon />}
                          {sortColumn === "lastName" &&
                            sortOrder === "desc" && <KeyboardArrowUpIcon />}
                          {(sortColumn !== "lastName" ||
                            sortOrder === "original") && (
                            <KeyboardArrowRightIcon />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="pro-th-pd"
                      onClick={() => toggleSortOrder("email")}
                    >
                      <div style={{ display: "flex", alignItems: "center",cursor:'pointer'}}>
                        <div>Email</div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {sortColumn === "email" &&
                            sortOrder === "asc" && <KeyboardArrowDownIcon />}
                          {sortColumn === "email" &&
                            sortOrder === "desc" && <KeyboardArrowUpIcon />}
                          {(sortColumn !== "email" ||
                            sortOrder === "original") && (
                            <KeyboardArrowRightIcon />
                          )}
                        </div>
                      </div>
                    </th>
                    {roleValue === "3" && (
                      <th
                        className="td-center pro-th-pd"
                        onClick={() => toggleSortOrder("id")}
                      >
                        <div style={{ display: "flex", alignItems: "center",justifyContent:'center',cursor:'pointer' }}>
                        <div>Associate Id</div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {sortColumn === "id" &&
                            sortOrder === "asc" && <KeyboardArrowDownIcon />}
                          {sortColumn === "id" &&
                            sortOrder === "desc" && <KeyboardArrowUpIcon />}
                          {(sortColumn !== "id" ||
                            sortOrder === "original") && (
                            <KeyboardArrowRightIcon />
                          )}
                        </div>
                      </div>
                      </th>
                    )}
                    <th className="td-center pro-th-pd">Status</th>
                    <th className="td-center pro-th-pd">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData.length > 0 &&
                    sortedData.map((value, index) => (
                      <tr key={index}>
                        <td className="pro-th-pd">{value.firstName}</td>
                        <td className="pro-th-pd">
                          {value.lastName ? value.lastName : "N/A"}
                        </td>
                        <td className="pro-th-pd">
                          {value.email ? value.email : "N/A"}
                        </td>
                        {roleValue === "3" && (
                          <td className="td-center pro-th-pd">
                            {value.id ? value.id : "N/A"}
                          </td>
                        )}
                        <td className="td-center">
                          {value.status === "Active" ? (
                            <img
                              className="act-img"
                              src={require("../../assets/Images/active.png")}
                              alt=""
                            />
                          ) : (
                            value.status === "Inactive" && (
                              <img
                                className="act-img"
                                src={require("../../assets/Images/inactive.png")}
                                alt=""
                              />
                            )
                          )}
                        </td>
                        <td className="td-center">
                          <p
                            className="pointer edit-btn"
                            onClick={() => {
                              edit(value);
                            }}
                          >
                            <EditIcon style={{ width: "17px" }} />
                            Edit
                          </p>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="not-found-txt">
              {!showLoader && sortedData.length === 0 ? "No Data Found" : ""}
            </div>
          </Box>
        </Paper>
        <Dialog
          PaperProps={{
            sx: {
              margin: "10px",
            },
            sm: {
              width: "100%",
              height: "100%",
              maxWidth: "100%",
            },
          }}
          open={open}
          maxWidth="xs"
          fullWidth={true}
        >
          <CreateEmployeeClient
            closeModal={handleClose}
            modalValue={modalValue}
            selectedUser={selectedUser}
            getclients={getClientApi}
            from="header"
          />
        </Dialog>
        <Loader open={showLoader} />
      </Grid>
    </Box>
  );
}
