
export default function ErrorPage() {
  return (
    <div id="error-page" style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i></i>
      </p>
    </div>
  );
}