import { useEffect } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./Auth/Login";
import SignUp from "./Auth/SignUp";
import Layout from "./Components/Layout";
import Home from "./Pages/home";
import Members from "./Pages/associates";
import Clients from "../src/Pages/Admin/clients-list";
import Employees from "../src/Pages/Admin/employees";
import Reports from "../src/Pages/Admin/Reports";
import UpdateProfile from "../src/Pages/Profile/updateProfile";
import ChangePassword from "../src/Pages/Profile/changePassword";
import ResetPassword from "./Auth/ForgotPassword";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ErrorPage from "./Components/NotFound";
import ProtectedRoute from "./Components/Guards/PrivateRoute";
import PublicRoute from "./Components/Guards/PublicRoute";
import "react-toastify/dist/ReactToastify.css";
import Charts from "./Pages/Admin/Charts";
import ReactGA from 'react-ga';
const TRACKING_ID = 'G-6VRN2FZD5C';

ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    const pageView = window.location.pathname + window.location.search;
    ReactGA.pageview(pageView);
  }, []);
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="/signup" element={<SignUp />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Layout Component={Home}></Layout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/members/:id"
        element={
          <ProtectedRoute>
            <Layout Component={Members}></Layout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/clients"
        element={
          <ProtectedRoute>
            <Layout Component={Clients}></Layout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/employees"
        element={
          <ProtectedRoute>
            <Layout Component={Employees}></Layout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/reports"
        element={
          <ProtectedRoute>
            <Layout Component={Reports}></Layout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/charts"
        element={
          <ProtectedRoute>
            <Layout Component={Charts}></Layout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/changePassword"
        element={
          <ProtectedRoute>
            <Layout Component={ChangePassword}></Layout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Layout Component={UpdateProfile}></Layout>
          </ProtectedRoute>
        }
      ></Route>
      <Route path="/resetPassword" element={<ResetPassword />}></Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
