import { Box, Grid, Toolbar } from '@mui/material'
import React from 'react'
import Header from './Header'
import { ToastContainer } from 'react-toastify'

export default function Layout(props) {
  const { Component } = props
  return (
    <Box component="main" sx={{ p: 2,height:"100%" }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Header></Header>
      </Grid>
      <Grid item xs>
        <Toolbar style={{height:"60px"}}/>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
          closeOnClick
          theme="light"
        />
        <Component></Component>
      </Grid>
    </Grid>
    </Box>
  )
}
