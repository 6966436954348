import { FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
import Button from '@mui/material/Button';
import CloseIcon from "@mui/icons-material/Close";
import instance from '../../Utils/api';
import * as yup from "yup";
import { Formik } from "formik";
import Loader from '../../Components/Loader';
import { toast } from 'react-toastify';

export default function CreateEmployeeClient({ ...props }) {
  const [showLoader, setShowLoader] = useState(false);
  const selectedUser = props.selectedUser || "";

  const createAccount = async (values) => {
    let data = {};
    data.firstName = values.firstName;
    if (values.lastName) {
      data.lastName = values.lastName;
    }
    if (props.modalValue.label === "Create Customer") {
      data.role = 2;
    } else if (props.modalValue.label === "Create Associate") {
      data.role = 3;
    }
    if (values.email) {
      data.email = values.email;
    }
    if (values.status) {
      data.status = values.status
    }
    if (values.employeeId) {
      data.id = values.employeeId
    }
    setShowLoader(true);
    const requestedOptions = {}
    if (props.modalValue && (props.modalValue.label === "Create Customer" || props.modalValue.label === "Create Associate")) {
      requestedOptions.method = 'POST'
      requestedOptions.url = "user"
      requestedOptions.data = data
    }
    else {
      requestedOptions.method = 'PUT'
      requestedOptions.url = `user/${selectedUser._id}`
      requestedOptions.data = data
    }
    await instance(
      requestedOptions
    )
      .then((data) => {
        if (data.status === "SUCCESS!") {
          props.closeModal();
          toast.success(props.modalValue && (props.modalValue.label === "Create Customer" || props.modalValue.label === "Create Associate") ? "Created Successfully!" : "Updated Successfully!");
          props.getclients();
          if (props && props.from === "header") {
            props.getclients();
          }
          else {
            props.getclients();
            props.getEmployees();
          }
          setShowLoader(false);
        } else {
          setShowLoader(false);
          toast.error(data.error.message || "API Error");
        }
      });
  };
  return (
    <div>
      <Paper>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "10px" }}>
          <div className='dialog-header'>
            {props.modalValue.userType === "Associate" ? props.modalValue.label : props.modalValue.label
            }
          </div>
          <div className='close-btn'
            onClick={() => props.closeModal()}
          >
            <CloseIcon className="close-icon" />
          </div>
        </div>
      </Paper>
      <div style={{ padding: "0px 20px" }}>
        <Formik enableReinitialize={true}
          initialValues={{
            firstName: selectedUser && selectedUser.firstName ? selectedUser.firstName : "",
            lastName: selectedUser && selectedUser.lastName ? selectedUser.lastName : "",
            email: selectedUser && selectedUser.email ? selectedUser.email : "",
            employeeId: selectedUser && selectedUser.id ? selectedUser.id : "",
            status: selectedUser && selectedUser.status ? selectedUser.status : "Active",
          }}
          onSubmit={createAccount}
          validationSchema={yup.object({
            firstName: yup.string().required("This field is required"),
            email: yup
              .string()
              .email("Invalid Email")
              .required("This field is required"),
            status: yup.string().required("This field is required"),
            employeeId: props.modalValue.userType === "Associate" && yup.string().required("This field is required")
          })}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                label="Enter First Name *"
                margin="normal"
                size="small"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName &&
                  Boolean(formik.errors.firstName)
                }
                helperText={
                  formik.touched.firstName && formik.errors.firstName
                }
                InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
                inputProps={{
                  style: {
                    height: "34px",
                    fontSize: "14px",
                  },
                }}
              />
              <TextField
                fullWidth
                id="lastName"
                name="lastName"
                label="Enter Last Name"
                margin="normal"
                size="small"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName &&
                  Boolean(formik.errors.lastName)
                }
                helperText={
                  formik.touched.lastName && formik.errors.lastName
                }
                InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
                inputProps={{
                  style: {
                    height: "34px",
                    fontSize: "14px",
                  },
                }}
              />
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Enter Email *"
                margin="normal"
                size="small"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={
                  formik.touched.email && Boolean(formik.errors.email)
                }
                helperText={formik.touched.email && formik.errors.email}
                InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
                inputProps={{
                  style: {
                    height: "34px",
                    fontSize: "14px",
                  },
                }}
              />
              {props.modalValue.userType === "Associate" && (
                <TextField
                  fullWidth
                  id="employeeId"
                  name="employeeId"
                  label="Enter Associate Id *"
                  margin="normal"
                  size="small"
                  value={formik.values.employeeId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.employeeId &&
                    Boolean(formik.errors.employeeId)
                  }
                  helperText={
                    formik.touched.employeeId && formik.errors.employeeId
                  }
                  InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
                  inputProps={{
                    style: {
                      height: "34px",
                      fontSize: "14px",
                    },
                  }}
                />

              )}
              <FormControl fullWidth style={{ marginTop: "15px" }}>
                <InputLabel id="demo-simple-select-label" style={{ fontSize: 14, color: "#575757" }}>Status</InputLabel>
                <Select
                  name="status" style={{ width: "100%" }}
                  id="status"
                  value={formik.values.status}
                  label="Status"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.status &&
                    Boolean(formik.errors.status)
                  }
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
                <FormHelperText style={{ color: "#D32F2F", margin: "4px 14px 0px" }}>{formik.touched.status &&
                  formik.errors.status}</FormHelperText>
              </FormControl>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="button"
                type="submit"
              >
                {props.modalValue && (props.modalValue.label === "Create Customer" || props.modalValue.label === "Create Associate") ? "Create" : "Update"}
              </Button>
            </form>
          )}
        </Formik>
        <Loader open={showLoader} />
      </div>
    </div>
  )
}
