import {
  Box,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import instance from "../../Utils/api";
import moment from "moment/moment";
import StarIcon from "@mui/icons-material/Star";
import Loader from "../../Components/Loader";
import { DateRange } from "react-date-range";
import { DateRangeOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
export default function Filter({ ...props }) {
  const [reviews, setReviews] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [suspense, setSuspence] = useState(true);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectedDate, SetselectedDate] = useState([
    {
      fromDate: null,
      toDate: null,
    },
  ]);

  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenDatePicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getFilterFeedbackData = async () => {
    const userId = props.employeeId;
    setShowLoader(true);
    let urlParams = "";
    const fromDate =
      selectedDate &&
      selectedDate[0].fromDate &&
      selectedDate[0].fromDate.getTime();
    const toDate =
      selectedDate &&
      selectedDate[0].toDate &&
      selectedDate[0].toDate.getTime();
    setShowLoader(true);
    if (checked) {
      urlParams = "";
    } else {
      const queryString = [];
      if (fromDate !== undefined && fromDate !== null) {
        queryString.push("fromDate=" + fromDate);
      }
      if (toDate !== undefined && toDate !== null) {
        queryString.push("toDate=" + toDate);
      }
      urlParams = queryString.length > 0 ? "&" + queryString.join("&") : "";
    }
    await instance({
      method: "GET",
      url: `submission?user=${userId}&project=${props.projectId}${urlParams}`,
      validateStatus: () => true,
    }).then((data) => {
      if (data.status === "SUCCESS!") {
        setShowLoader(false);
        setSuspence(false);
        if (data.result.length > 0) {
          setReviews(data.result);
        } else {
          setReviews([]);
        }
        setShowLoader(false);
      } else {
        setShowLoader(false);
        alert(data.error.message || "API Error!");
      }
    });
  };

  const applyFilter = () => {
    setOpenDatePicker(false);
    if (
      selectedDate[0].fromDate === null &&
      selectedDate[0].toDate === null &&
      !checked
    ) {
      toast.error("Please choose above options");
    } else {
      getFilterFeedbackData();
    }
  };
  const [checked, setChecked] = useState(false);
  function handleChange(e) {
    setChecked(e.target.checked);
    SetselectedDate([{}]);
  }

  return (
    <div ref={wrapperRef} style={{ height: "100%", ...(props.isFullscreen
      ? {
          position: 'relative',
          top: '30px',
        }
      : {}), }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          background: "#006161",
        }}
        ref={wrapperRef}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            padding: "10px 20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              color: "white",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenDatePicker(!openDatePicker);
                  setChecked(false);
                }}
              >
                <p
                  style={{
                    margin: "0px",
                    fontWeight: "500",
                    fontSize: "14px",
                    letterSpacing: "0.8px",
                  }}
                >
                  Select Range:
                </p>
                &nbsp;
                <DateRangeOutlined />
              </div>
              <div
                style={{
                  padding: "0px 15px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "14px",
                  letterSpacing: "0.8px",
                }}
              >
                <input
                  value="All"
                  type="checkbox"
                  checked={checked}
                  style={{ width: "20px", height: "20px" }}
                  onChange={handleChange}
                />
                &nbsp;All
              </div>
            </div>

            <div>
              <p
                style={{
                  margin: "0px",
                  fontWeight: "500",
                  letterSpacing: "0.8px",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => applyFilter()}
              >
                Apply Filter
              </p>
            </div>
          </Box>
          {openDatePicker && (
            <div className="date" style={{ position: "absolute", zIndex: 1 }}>
              <DateRange
                editableDateInputs={true}
                onChange={(item) => {
                  setState([item.selection]);
                  SetselectedDate([
                    {
                      fromDate: item.selection.startDate,
                      toDate: item.selection.endDate,
                    },
                  ]);
                }}
                moveRangeOnFirstSelection={false}
                ranges={state}
              />
            </div>
          )}
        </div>
      </div>

      {(selectedDate[0].fromDate || selectedDate[0].toDate) && (
        <div className="filter-date">
          Filtered Results:{" "}
          {selectedDate[0].fromDate &&
            moment(selectedDate[0].fromDate).format("DD/MM/YYYY")}{" "}
          -
          {selectedDate[0].toDate &&
            moment(selectedDate[0].toDate).format("DD/MM/YYYY")}
        </div>
      )}
      <div
        style={{ padding: "10px 20px",height:'350px',overflowY:'scroll',...(props.isFullscreen) ? {height:'470px'}:{} }}
        onClick={() => setOpenDatePicker(false)}
      >
        {reviews.length > 0 &&
          reviews.map((value, index) => (
            <div key={index}>
              <div
                style={{
                  padding: "10px 0px",
                  fontWeight: "600",
                  fontSize: "16px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>{moment(value.timestamp).format("MMM-YYYY")}</div>
                <div style={{fontSize:'10px',fontWeight:'normal'}}>(Feedback Submitted On: {moment(value.createTimestamp).format("DD-MMM-YYYY")})</div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Overall Rating: {value.rating ? value.rating : "N/A"}{" "}
                  <StarIcon style={{ color: "#FFDF00" }} />
                </div>
              </div>
              <table>
                <tbody>
                  {value &&
                    value.review &&
                    value.review.map((data, index) => (
                      <tr key={index}>
                        <td className="qns-td">{data.question}</td>
                        <td className="ans-td">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {data.answer}
                              <StarIcon style={{ color: "#FFDF00" }} />
                            </div>
                            <div>{data.note}</div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div
                style={{
                  padding: "10px 0px",
                  borderBottom: "2px solid black",
                  fontSize: "14px",
                  marginBottom: "10px",
                }}
              >
                Comment: {value.comment ? value.comment : "N/A"}
              </div>
            </div>
          ))}

        {suspense && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}
          >
            {" "}
            <img
              className="filter-img"
              src={require("../../assets/Images/filter.png")}
              alt=""
            />
          </div>
        )}
        {!suspense && reviews.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}
          >
            {" "}
            <img
              className="not-found-img"
              src={require("../../assets/Images/notFound.gif")}
              alt=""
            />
          </div>
        )}
      </div>
      <Loader open={showLoader} />
    </div>
  );
}
