import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import instance from "../../Utils/api";
import Loader from "../../Components/Loader";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Link,
  Box,
  Typography,
  FormHelperText,
} from "@mui/material";
import { GoogleAuthProvider, signInWithPopup, OAuthProvider } from "firebase/auth";
import auth from "../../Utils/firebase";
import { ToastContainer, toast } from "react-toastify";
 import ReCAPTCHA from "react-google-recaptcha";
import { siteKey } from "../../Utils/recaptcha";

export default function Login() {
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const captchaRef = useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const validationSchema = yup.object({
    email: yup.string("Enter your email").required("This field is required"),
    password: yup.string().required("This field is required"),
    recaptcha: yup.string().required('Please verify you are human'),
  });

  const login = async (value) => {
    const data = {};
    data.email = value.email;
    data.password = value.password;
    data["g-recaptcha-response"] = captchaRef.current.getValue();
    setShowLoader(true);
    await instance({
      method: "POST",
      url: "user/login",
      data,
    }).then((response) => { 
      let obj = {};
      obj = response;
      if (obj.status === "SUCCESS!") { 
        //captchaRef.current.reset();
        setShowLoader(false);
        instance.defaults.headers.common['Authorization'] = obj.result.token;
        localStorage.setItem("token", obj.result.token);
        localStorage.setItem("role", obj.result.role);
        const expirationDate = rememberMe ? Date.now() + 7 * 24 * 60 * 60 * 1000 : null;
        var testObject = { email: value.email, password: value.password, expirationDate };
        if (rememberMe) {
          localStorage.setItem('rememberedUser', JSON.stringify(testObject));
        } else {
          localStorage.removeItem('rememberedUser');
        }
        navigate("/dashboard");
      } else {
        toast.error(response.error.message);
        setShowLoader(false);
        captchaRef.current.reset();
      }
    }).catch((error) => {
      setShowLoader(false);
    })
  };

  useEffect(() => {
    const rememberedUser = localStorage.getItem('rememberedUser');
    if (rememberedUser) {
      const { email: storedUsername, password: storedPassword, expirationDate } = JSON.parse(
        rememberedUser
      );
      if (expirationDate && Date.now() > expirationDate) {
        localStorage.removeItem('rememberedUser');
      } else {
        setEmail(storedUsername);
        setPassword(storedPassword);
        setRememberMe(true);
      }
    }
  }, []);
 
  const signInwithOAuth = (signInType) => {
    let provider = new GoogleAuthProvider();
    if (signInType === 'MICRO_SOFT') {
      provider = new OAuthProvider('microsoft.com');
    }
    return new Promise((resolve, reject) => {
      signInWithPopup(auth, provider)
        .then((result) => {
          const data = {
            email: result._tokenResponse.email,
            firstName: result._tokenResponse.firstName
          }
          instance({
            method: "POST",
            url: "user/authLogin",
            data
          }).then((data) => {
            if (data.status === "SUCCESS!") {
              instance.defaults.headers.common['Authorization'] = data.result.token;
              localStorage.setItem("token", data.result.token);
              localStorage.setItem("role", data.result.role);
              navigate("/dashboard");
              //resolve(data);
            }
          }).catch((error) => {
            toast.error(error.message || "API Error")
          })
          //   axios.post(`http://18.236.244.242:4007/api/user/authLogin`, user)
          //     .then((response) => {
          //       if (response) {
          //         instance.defaults.headers.common['Authorization'] = response.data.result.token;
          //         localStorage.setItem("token", response.data.result.token);
          //         localStorage.setItem("role", response.data.result.role);
          //         navigate("/dashboard");
          //         resolve(user);
          //       }

          //     }).catch((error) => {
          //       reject(error);
          //     });
        });
    });
  }

  return (
    <Box style={{ height: "100vh", overflowY: "auto" }}>
      <Grid container style={{height:"100%"}}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "430px",
              margin: "20px auto",
              padding: "0px 32px",
            }}
          >
            <img height={60} width={60}
              alt=""
              src={require("../../assets/Images/edvenswa-logo.png")}
            />
            <Typography
              component="h1"
              variant="h5"
              sx={{ padding: "0px 0px 16px 0px", color: "#008080", marginBottom: "20px" }}
            >
              Feedback
            </Typography>
            <Typography
              component="div"
              variant="div"
              className="login-txt"
            >
              Please sign in to your account.
            </Typography>
            <Box sx={{ mt: 1, width: "100%" }}>
              <Formik enableReinitialize
                initialValues={{
                  email: email,
                  password: password,
                  recaptcha: "",
                }}
                onSubmit={login}
                validationSchema={validationSchema}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Enter Email"
                      margin="normal"
                      size="small"
                      value={formik.values.email}
                      onChange={(e) => {
                        formik.setFieldValue("email", e.target.value);
                      }}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      InputLabelProps={{
                        style: { fontSize: 14, color: "#575757" },
                        shrink: true,
                      }}
                      inputProps={{
                        style: {
                          height: "34px",
                          fontSize: "14px",
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      id="password"
                      name="password"
                      label="Enter Password"
                      type="password"
                      margin="normal"
                      size="small"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
                      inputProps={{
                        style: {
                          height: "34px",
                          fontSize: "14px",
                        },
                      }}
                    />
                    <div style={{ marginBottom: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>

                        <input style={{ width: "15px", height: "15px" }}
                          type="checkbox"
                          checked={rememberMe}
                          onChange={(e) => setRememberMe(e.target.checked)}
                        />
                        <span style={{ fontSize: "13px" }}>Remember me</span>
                      </div>
                      <Link
                        href="/resetPassword"
                        variant="body2"
                        sx={{
                          textDecoration: "none",
                        }}
                        className="signup-txt"
                      >
                        Forgot password?
                      </Link>      
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "25px 0px 10px 0px", flexDirection: "column" }}>
                      <ReCAPTCHA theme="light" name="recaptcha" onChange={(value) => formik.setFieldValue('recaptcha', value)} sitekey={siteKey} ref={captchaRef} />
                      <FormHelperText style={{ color: "#D32F2F",margin:"4px 14px 0px" }}>{formik.touched.recaptcha &&
                        formik.errors.recaptcha}</FormHelperText>
                    </div>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 2, mb: 2 }}
                      className="button"
                      type="submit"
                    >
                      Login
                    </Button>
                  </form>
                )}
              </Formik>
            </Box>
            <div style={{ fontSize: "13px", paddingBottom: "10px" }}>
              Don't have an account?{" "}
              <Link
                href="/signup"
                variant="body2"
                sx={{
                  textDecoration: "none",
                }}
                className="signup-txt"
              >
                {"Sign Up"}
              </Link>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <p className="line"></p><p style={{ width: "100%", textAlign: "center", fontSize: "12px", color: "#575757" }}>Login With</p><p className="line"></p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <div className="auth-btn" onClick={() => { signInwithOAuth('GOOGLE') }} >
                <img className="auth-img"
                  alt=""
                  src={require("../../assets/Images/google.png")}
                />
                <div style={{ fontSize: "13px", paddingLeft: "5px" }}>Google</div>
              </div>

              <div className="auth-btn" onClick={() => { signInwithOAuth('MICRO_SOFT') }} >
                <img className="auth-img"
                  alt=""
                  src={require("../../assets/Images/microsoft.png")}
                />
                <div style={{ fontSize: "13px", paddingLeft: "5px" }}>Microsoft</div>
              </div>
            </div>
          </Box>
        </Grid>
        <Loader open={showLoader} handleClose={() => setShowLoader(false)} />
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={true}
          closeOnClick
          theme="light"
        />
      </Grid>
    </Box>
  );
}
