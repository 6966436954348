import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LinearProgress } from '@mui/material';
import instance from '../../Utils/api';
import Loader from '../../Components/Loader';
import { toast } from 'react-toastify';


export default function ChangePassword(props) {
    const [passwordStrength, setPasswordStrength] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [toggle_password, setToggle_password] = useState(false);

    const validatePassword = (password) => {
        const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/g;
        if (password === "" || password === undefined) {
            return setPasswordStrength("");
        }
        else if (!passwordRegex.test(password)) {
            return setPasswordStrength("Weak");
        } else if (password.length < 8) {
            return setPasswordStrength("Weak");
        } else if (password.length <= 10) {
            return setPasswordStrength("Average");
        } else {
            return setPasswordStrength("Strong");
        }
    };

    const validationSchemas = yup.object({
        password: yup.string().required("This field is required"),
        newPassword: yup.string()
            .required("This field is required")
            .min(8, "Must be 8 characters or more")
            .matches(/[a-z]+/, "One lowercase character")
            .matches(/[A-Z]+/, "One uppercase character")
            .matches(/[@$!%*#?&]+/, "One special character")
            .matches(/\d+/, "One number"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('newPassword'), null], 'Password & Confirm Password should be same')
            .required("This field is required"),

    });

    const togglePasswordHide = () => {
        setToggle_password(!toggle_password);
    };

    const formik = useFormik({
        initialValues: {
            password: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: validationSchemas,
        onSubmit: async (value, { resetForm }) => {
            setShowLoader(true);
            let data = {};
            data.password = value.password;
            data.newPassword = value.newPassword;
            data.confirmPassword = value.confirmPassword;
            await instance({
                method: "POST",
                url: `user/changePassword`,
                data,
                validateStatus: () => true
            }).then((resp) => {
                if (resp.status === "SUCCESS!") {
                    setShowLoader(false);
                    resetForm();
                    toast.success("Password Updated Successfully");

                }
                else {
                    setShowLoader(false);
                    toast.error(resp.error.message || "API Error");
                }
            });
        },
    });

    return (
        <Grid container component="main">
            <Grid item xs={12} sm={12} md={12}>
                <Box
                    sx={{
                        my: 4,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Create new password
                    </Typography>
                    <Typography component="div" variant="div" sx={{
                        fontWeight: '400', color: "grey", fontSize: "12px", paddingTop: "16px"
                    }}>
                        Password must contain at least 8 characters, including uppercase and lowercase letters, a number, and a symbol.
                    </Typography>

                    <Box sx={{ mt: 1, maxWidth: "430px" }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', float: "right", alignSelf: "self-end", paddingTop: "16px" }}>
                            <Box sx={{ mr: 1 }}>
                                <Typography variant="body2" color="text.secondary">
                                    {passwordStrength}
                                </Typography>
                            </Box>
                            <Box sx={{ width: '100px', color: passwordStrength === "Weak" ? "red" : passwordStrength === "Average" ? "orange" : passwordStrength === "Strong" ? "green" : "grey" }}>
                                <LinearProgress variant="determinate" color='inherit'
                                    value={passwordStrength === "Weak" ? 30 : passwordStrength === "Average" ? 70 : passwordStrength === "Strong" ? 100 : 0} />
                            </Box>
                        </Box>
                        <form onSubmit={formik.handleSubmit}>
                            <TextField
                                fullWidth
                                id="password"
                                name="password"
                                label="Enter Old Password"
                                type={toggle_password ? "text" : "password"}
                                margin="normal"
                                size='small'
                                value={formik.values.password}
                                onChange={(e) => {
                                    formik.setFieldValue("password", e.target.value);
                                }}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                InputLabelProps={{ style: { fontSize: 14, color: "#575757" }, }}
                                inputProps={{
                                    style: {
                                        height: "34px",
                                        fontSize: "14px"
                                    },
                                }}
                                autoFocus

                            />
                            <TextField
                                fullWidth
                                id="newPassword"
                                name="newPassword"
                                label="Enter New Password"
                                type={toggle_password ? "text" : "password"}
                                margin="normal"
                                size='small'
                                value={formik.values.newPassword}
                                onChange={(e) => {
                                    formik.setFieldValue("newPassword", e.target.value);
                                    validatePassword(e.target.value)
                                }}
                                onBlur={formik.handleBlur}
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                helperText={formik.touched.newPassword && formik.errors.newPassword}
                                InputLabelProps={{ style: { fontSize: 14, color: "#575757" }, }}
                                inputProps={{
                                    style: {
                                        height: "34px",
                                        fontSize: "14px"
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                id="confirmPassword"
                                name="confirmPassword"
                                label="Enter Confirm Password"
                                type={toggle_password ? "text" : "password"}
                                margin="normal"
                                size='small'
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                InputLabelProps={{ style: { fontSize: 14, color: "#575757" }, }}
                                inputProps={{
                                    style: {
                                        height: "34px",
                                        fontSize: "14px"
                                    },
                                }}
                            />
                            <div style={{ color: "#008080" }} onClick={
                                togglePasswordHide
                            }>{toggle_password ? "Hide Password" : "Show Password"}</div>
                            <Button fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                className='button' type="submit">
                                Update
                            </Button>
                        </form>
                    </Box>
                </Box>
            </Grid>
            <Loader open={showLoader} handleClose={() => setShowLoader(false)} />
        </Grid>
    )
}