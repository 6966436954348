import React, { useEffect, useState } from "react";
import instance from "../Utils/api";
import {
  Box,
  Dialog,
  Grid,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { generatePath, useNavigate } from "react-router-dom";
import CreateProject from "./Admin/createProject";
import Loader from "../Components/Loader";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Projects({ ...props }) {
  const [projectList, setProjectList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const role = localStorage.getItem("role");
  const [modalValue, setModalValue] = useState("");
  const [editProjectValues, setEditProjectValues] = useState();
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmDialogValue, setConfirmDialogValue] = useState("");
  const [selectedProId, setSelectedProId] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [suspense, setSuspense] = useState(true);
  const [open, setOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");

  const getProjects = async () => {
    setShowLoader(true);
    await instance({
      method: "GET",
      url: "project",
      validateStatus: () => true,
    }).then((resp) => {
      if (resp.status === "SUCCESS!") {
        setShowLoader(false);
        setSuspense(false);
        setProjectList(resp.result);
        setSearchData(resp.result);
      } else {
        setShowLoader(false);
      }
    });
  };

  useEffect(() => {
    getProjects();
  }, []);

  const navigate = useNavigate();

  const handleClose = (value) => {
    setModalOpen(false);
  };

  const SearchList = (e) => {
    setSearchValue(e);
    const original = searchData;
    if (e.length > 0) {
      const filterdata = original.filter((item) => {
        const query = e.toLowerCase();
        return item.title.toLowerCase().includes(query);
      });
      setProjectList(filterdata);
    } else {
      setProjectList(original);
    }
  };

  const navToMembers = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(generatePath("/members/:id", { id }));
    window.location.reload();
  };

  const toggleSortOrder = () => {
    let newSortOrder = sortOrder ;
    let sortedProjects;

    if (newSortOrder === "asc") {
      sortedProjects = [...projectList].sort((a, b) =>
        a.title.localeCompare(b.title)
      );
    } else if (newSortOrder === "desc") {
      sortedProjects = [...projectList].sort((a, b) =>
        b.title.localeCompare(a.title)
      );
    } else if (newSortOrder === "original") {
      sortedProjects = [...projectList].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    }
    setProjectList(sortedProjects);

    if (newSortOrder === "asc") {
      newSortOrder = "desc";
    } else if (newSortOrder === "desc") {
      newSortOrder = "original";
    } else if (newSortOrder === "original") {
      newSortOrder = "asc";
    }
    setSortOrder(newSortOrder);
  };

  const closeSearch = () => {
    setSearchValue("");
    getProjects();
  };

  const resendInvite = async (id, e) => {
    setShowLoader(true);
    setConfirmDialog(false);
    await instance({
      method: "GET",
      url: `user/resendInvite/${selectedProId}`,
      validateStatus: () => true,
    }).then((resp) => {
      if (resp.status === "SUCCESS!") {
        setShowLoader(false);
        setConfirmDialog(false);
        toast.success("Invitation sent successfully");
      } else {
        setShowLoader(false);
        toast.error(resp.error.message || "API Error");
      }
    });
  };
  const askFeedbackApi = async () => {
    setShowLoader(true);
    setConfirmDialog(false);
    await instance({
      method: "GET",
      url: `project/askFeedback/${selectedProId}`,
      validateStatus: () => true,
    }).then((resp) => {
      if (resp.status === "SUCCESS!") {
        setShowLoader(false);
        setConfirmDialog(false);
        toast.success("Feedback request sent!");
      } else {
        setShowLoader(false);
        toast.error(resp.error.message || "API Error");
      }
    });
  };
  const handleActions = async (id, e, value) => {
    e.preventDefault();
    e.stopPropagation();
    setConfirmDialogValue(value);
    setSelectedProId(id);
    setConfirmDialog(true);
  };

  const handleConfirm = () => {
    if (confirmDialogValue === "resend") {
      resendInvite();
    } else if (confirmDialogValue === "askFeedback") {
      askFeedbackApi();
    }
  };

  const filteredUsers =
    parseInt(role) === 1
      ? projectList
      : projectList.filter((user) => user.status === "Active");
  return (
    <Box style={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          px: 2,
          justifyContent: "space-between",
        }}
      >
        <Grid item md={8}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              id="search-bar"
              className="text"
              onInput={(e) => {
                SearchList(e.target.value);
              }}
              label="Search by Project"
              variant="outlined"
              placeholder="Search..."
              size="small"
              value={searchValue}
              style={{ width: "100%", margin: "10px 0px", padding: "0px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => closeSearch()}
                    style={{ cursor: "pointer" }}
                    position="start"
                  >
                    <CloseIcon className="close-icon" />
                  </InputAdornment>
                ),
                style: {
                  padding: "0px",
                },
              }}
              InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
            />
          </div>
        </Grid>
        {role === "1" && (
          <Button
            variant="contained"
            className="button create-btn"
            // style={{ margin: "0px", height: "40px" }}
            onClick={() => {
              setOpen(true);
              setModalValue("create");
            }}
          >
            Create Project
          </Button>
        )}
      </Box>
      <div style={{ overflow: "scroll", width: "100%" }}>
        <table>
          <thead>
            <tr className="project-td">
              <th className="pro-th-pd flex-th" onClick={toggleSortOrder}>
                Project{" "}
                {sortOrder === "asc" ? (
                  <KeyboardArrowRightIcon />
                ) : sortOrder === "desc" ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </th>
              {role === "1" && <th className="pro-th-pd">Customer</th>}
              {role === "1" && <th className="pro-th-pd">Actions</th>}
              {role === "1" && <th className="td-center pro-th-pd">Status</th>}
              {role === "1" && <th className="td-center pro-th-pd">Edit</th>}
            </tr>
          </thead>
          <tbody>
            {filteredUsers.length > 0 &&
              filteredUsers.map((value, index) => (
                <tr key={index}>
                  <td
                    className="project-td pro-th-pd"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => navToMembers(value._id, e)}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <div className="td-title">
                          {" "}
                          {value.title}
                        </div>
                        <div className="td-desc">{value.description}</div>
                      </div>
                      <div>
                        {role === "2" && (
                          <KeyboardArrowRightIcon style={{ color: "grey" }} />
                        )}
                      </div>
                    </div>
                  </td>
                  {role === "1" && (
                    <td className="project-td pro-th-pd">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="td-title"
                      >
                        {value.firstName}&nbsp;{value.lastName}
                      </div>
                    </td>
                  )}

                  {role === "1" && (
                    <td className="project-td">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {value.isFirstLogin && role === "1" && (
                          <p
                            className="pro-actions"
                            onClick={(e) =>
                              handleActions(value._id, e, "resend")
                            }
                          >
                            Resend Invite
                          </p>
                        )}
                        <p
                          className="pro-actions"
                          onClick={(e) =>
                            handleActions(value._id, e, "askFeedback")
                          }
                        >
                          Feedback for Edvenswa
                        </p>
                      </div>
                    </td>
                  )}

                  {role === "1" && (
                    <td className="td-center">
                      {value.status === "Active" ? (
                        <img
                          className="act-img"
                          src={require("../assets/Images/active.png")}
                          alt=""
                        />
                      ) : (
                        value.status === "Inactive" && (
                          <img
                            className="act-img"
                            src={require("../assets/Images/inactive.png")}
                            alt=""
                          />
                        )
                      )}
                    </td>
                  )}
                  {role === "1" && (
                    <td className="td-center project-td">
                      <p
                        className="edit-btn pointer"
                        onClick={(e) => {
                          setModalOpen(true);
                          setModalValue("edit");
                          setEditProjectValues(value);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <EditIcon style={{ width: "17px" }} />
                        Edit
                      </p>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {!suspense && role === "1" && projectList.length === 0 && (
        <div className="not-found-txt">No Data Found</div>
      )}
      {!suspense && role !== "1" && filteredUsers.length === 0 && (
        <div className="not-found-txt">No Data Found</div>
      )}
      <Loader open={showLoader} />
      {suspense && <div className="not-found-txt">Please wait...</div>}
      <Dialog
        className="rm-scroll"
        maxWidth="md"
        fullScreen={fullScreen}
        fullWidth={true}
        sx={{
          margin: "10px",
        }}
        open={modalOpen}
      >
        <CreateProject
          closeModal={handleClose}
          modalValue={modalValue}
          getProjects={getProjects}
          editProjectValues={editProjectValues}
        ></CreateProject>
      </Dialog>
      <Dialog
        open={confirmDialog}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {confirmDialogValue === "resend"
              ? "Are you sure you want to resend the Invitation?"
              : "Are you sure you want to request feedback?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog(false)}>No</Button>
          <Button onClick={() => handleConfirm()}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className="rm-scroll"
        maxWidth="md"
        sx={{ margin: "10px" }}
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
      >
        <CreateProject
          closeModal={() => setOpen(false)}
          modalValue={modalValue}
          editProjectValues=""
        ></CreateProject>
      </Dialog>
    </Box>
  );
}
