import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, TextField, Link, Paper, Box, Grid, Typography, Modal } from '@mui/material';
import logo from '../../assets/Images/edvenswa-logo.png'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Loader from '../../Components/Loader';
import instance from '../../Utils/api';
import { ToastContainer, toast } from 'react-toastify';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
export default function ResetPassword() {
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    window.location.href = "/"
  }

  const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email("Invalid email")
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let data = {};
      data.email = values.email;
      setShowLoader(true);
      await instance({
        method: "POST",
        url: "user/forgotPassword",
        data
      }).then((resp => {
        if (resp.status === "SUCCESS!") {
          setOpen(true);
        }
        else {
          setShowLoader(false);
          toast.error(resp.error.message || "API Error")
        }
      })).catch(function (error) {
        setShowLoader(false)
      });
    }
  });
  return (
    <>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: "0px 32px",
              maxWidth: "430px",
              margin: "20px auto",
            }}
          >
            <img src={logo} alt="logo" height="60px" object="contain" />
            <Typography
              component="h1"
              variant="h5"
              sx={{ padding: "0px 0px 16px 0px", color: "#008080", marginBottom: "20px" }}
            >
              Feedback
            </Typography>
            <Typography
              component="div"
              variant="div"
              className="login-txt"
            >
              Forgot Password
            </Typography>
            <Box sx={{ mt: 1, width: "100%", }} >
              <div  >
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Enter Email"
                    margin="normal"
                    size='small'
                    type="text"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
                    inputProps={{
                      style: {
                        height: "34px",
                        fontSize: "14px",
                      },
                    }}
                  />

                  <Button fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    className='button' type="submit">
                    Reset
                  </Button>

                </form>
              </div>
            </Box>


            <div style={{ fontSize: "13px", padding: "8px 0px" }}>
              Already have an account?{" "}
              <Link
                href="/"
                variant="body2"
                className="signup-txt"
                sx={{
                  color: "#006161",
                  textDecoration: "none",
                }}
              >
                {"Login"}
              </Link>
            </div>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CheckCircleOutlineIcon style={{ width: "45px", height: "45px", color: "#3689AA" }} />
          <Typography>Success!</Typography>
          <Typography id="modal-modal-title" variant="div" component="div">
            Password reset request was sent Successfully. Please check your email to reset your password.
          </Typography>
          <Button fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            className='button' type="submit" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Modal>
      <Loader open={showLoader} handleClose={() => setShowLoader(false)} />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick
        theme="light"
      />
    </>
  )
}