import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import instance from "../../Utils/api";
import Loader from "../../Components/Loader";
import { DateRange, } from "react-date-range";
import { toast } from "react-toastify";
import { DateRangeOutlined } from "@mui/icons-material";

export default function Reports({ ...props }) {
  const [reviews, setReviews] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [projectValue, setProjectValue] = useState(null);
  const [employeeValue, setEmployeeValue] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectedDate, SetselectedDate] = useState([
    {
      fromDate: null,
      toDate: null,
    }
  ])
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getFilterFeedbackData = async () => {
    const fromDate = selectedDate && selectedDate[0].fromDate && selectedDate[0].fromDate.getTime();
    const toDate = selectedDate && selectedDate[0].toDate && selectedDate[0].toDate.getTime();
    setShowLoader(true);
    let urlParams = "";
    if (
      fromDate === null &&
      toDate === null &&
      projectValue === "All" &&
      employeeValue === "All"
    ) {
      urlParams = "/submission";
    } else {
      const queryString = [];
      if (projectValue !== null && projectValue.value !== "All") {
        queryString.push("project=" + projectValue.value);
      }
      if (employeeValue !== null && employeeValue.value !== "All") {
        queryString.push("user=" + employeeValue.value);
      }
      if (fromDate !== null && fromDate !== null) {
        queryString.push("fromDate=" + fromDate);
      }
      if (toDate !== null && toDate !== null) {
        queryString.push("toDate=" + toDate);
      }
      urlParams =
        "/submission" +
        (queryString.length > 0 ? "?" + queryString.join("&") : "");
    }
    await instance({
      method: "GET",
      url: `${urlParams}`,
      validateStatus: () => true,
    }).then((data) => {
      if (data.status === "SUCCESS!") {
        setShowLoader(false);
        setOpen(true);
        if (data.result.length > 0) {
          setReviews(data.result);
        } else {
          setReviews([]);
        }
        setShowLoader(false);
      } else {
        setShowLoader(false);
        alert(data.error.message || "API Error!");
      }
    });
  };

  const applyFilter = () => {
    setOpenDatePicker(false)
    getFilterFeedbackData();
  };

  const getEmployees = async () => {
    await instance({
      method: "GET",
      url: "user/getEmployees?role=3",
      validateStatus: () => true,
    }).then((data) => {
      if (data.status === "SUCCESS!") {
        var list = [];
        if (data.result.length > 0) {
          data.result.map(async (item) => {
            list.push({ label: (item.id ? (item.id + " -") : "") + " " + item.firstName + " " + (item.lastName ? item.lastName : ""), value: item._id});
          });
        }
        if (list.length > 0) {
          list.unshift({ label: "All", value: "All" });
          setEmployeeList(list);
        }
      } else {
        alert(data.error.message);
      }
    });
  };
  const getProjectMembers = async (id) => {
    await instance({
      method: "GET",
      url: `project/members/${id}`,
      validateStatus: () => true,
    }).then((data) => {
      if (data.status === "SUCCESS!") {
        var list = [];
        if (data.result && data.result.users) {
          if (data.result.users.length > 0) {
            data.result.users.map(async (item) => {
              list.push({ label: item.firstName, value: item._id });
            });
          }
          if (list.length > 0) {
            list.unshift({ label: "All", value: "All" });
            setEmployeeList(list);
          }
        }
      } else {
        alert(data.error.message || "API Error!");
      }
    });
  };
  const getProjects = async () => {
    await instance({
      method: "GET",
      url: "project",
      validateStatus: () => true,
    }).then((data) => {
      if (data.status === "SUCCESS!") {
        var list = [];
        if (data.result.length > 0) {
          data.result.map(async (item) => {
            list.push({ label: item.title, value: item._id });
          });
          if (list.length > 0) {
            list.unshift({ label: "All", value: "All" });
            setProjectList(list);
          }
        }
      } else {
        alert(data.error.message);
      }
    });
  };

  useEffect(() => {
    getEmployees();
    getProjects();
  }, []);

  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenDatePicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const downloadReportApi = async () => {
    setShowLoader(true)
    const fromDate = selectedDate && selectedDate[0].fromDate && selectedDate[0].fromDate.getTime();
    const toDate = selectedDate && selectedDate[0].toDate && selectedDate[0].toDate.getTime();
    let urlParams = "";
    if (
      fromDate === null &&
      toDate === null &&
      projectValue === "All" &&
      employeeValue === "All"
    ) {
      urlParams = "/downloadReport";
    } else {
      const queryString = [];
      if (projectValue !== undefined && projectValue !== null && projectValue.value !== "All") {
        queryString.push("project=" + projectValue.value);
      }
      if (employeeValue !== undefined && employeeValue !== null && employeeValue.value !== "All") {
        queryString.push("user=" + employeeValue.value);
      }
      if (fromDate !== undefined && fromDate !== null) {
        queryString.push("fromDate=" + fromDate);
      }
      if (toDate !== undefined && toDate !== null) {
        queryString.push("toDate=" + toDate);
      }
      urlParams =
        "/downloadReport" +
        (queryString.length > 0 ? "?" + queryString.join("&") : "");
    }
    await instance({
      method: "GET",
      url: `submission${urlParams}`,
      validateStatus: () => true,
    }).then((data) => {
      if (data.status === "SUCCESS!") {
        const downloadLink = document.createElement("a");
        downloadLink.href = data.result[0];
        downloadLink.download = `${data.fileName}`;
        downloadLink.click();
        downloadLink.remove();
        setShowLoader(false);
        toast.success("DownLoaded Successfully");
      } else {
        setShowLoader(false);
        toast.error(data.error.message || "API Error");
      }
    });
  }
  const downloadReport = async () => {
    setOpenDatePicker(false);
    if (selectedDate[0].fromDate === null && selectedDate[0].toDate === null && projectValue === undefined && employeeValue === undefined) {
      toast.error("Please choose above options")
    } else {
      downloadReportApi();
    }
  };

  return (
    <Grid container component="main">
      <Grid item xs={12} sm={12} md={12}>
        <Box
          sx={{
            my: 4,
            mx: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div ref={wrapperRef} style={{ height: "100%", maxWidth: "430px", width: "100%" }}>
            <Box sx={{ mt: 1, width: "100%" }}>
              <div className="date-flex">
                <div className="date-box" onClick={() => setOpenDatePicker(!openDatePicker)} style={{ width: "100%", flexDirection: "column", alignItems: "flex-start" }}>

                  <div className="to-from-label">Select Range &nbsp;<DateRangeOutlined /></div>
                  {(selectedDate[0].fromDate || selectedDate[0].toDate) &&
                    <div className="to-from-label" style={{ color: "black" }}>Selected Range:&nbsp;{selectedDate[0].fromDate && moment(selectedDate[0].fromDate).format("DD/MM/YYYY")}<sapn>&nbsp;-&nbsp;</sapn>{selectedDate[0].toDate && moment(selectedDate[0].toDate).format("DD/MM/YYYY")}</div>
                  }
                </div>
                {selectedDate[0].toDate && selectedDate[0].fromDate &&
                  <div style={{ padding: "0px 10px", color: "red", fontWeight: "600" }} onClick={() => {
                    SetselectedDate([{}]);
                    setState([{
                      startDate: new Date(),
                      endDate: new Date(),
                      key: "selection",
                    },]); setOpenDatePicker(false)
                  }}>Clear</div>
                }</div>
              {openDatePicker &&
                <div className="date">
                  <DateRange
                    editableDateInputs={true}
                    onChange={item => {
                      setState([item.selection]); SetselectedDate([
                        {
                          fromDate: item.selection.startDate,
                          toDate: item.selection.endDate
                        }
                      ])
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                    rangeColors={["blue", "pink", "red"]}

                  />
                </div>}
              <div style={{ padding: "10px 0px" }} onClick={() => setOpenDatePicker(false)}>
                <Autocomplete
                  value={projectValue}
                  onChange={(event, newValue) => {
                    setProjectValue(newValue);
                    if (newValue && newValue.value !== "All") {
                      getProjectMembers(newValue.value);
                      setEmployeeValue(null)
                    } else {
                      getEmployees();
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  id="controllable-states-demo"
                  options={projectList}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose Project" InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }} />
                  )}
                />
              </div>
              <div style={{ padding: "10px 0px" }} onClick={() => setOpenDatePicker(false)}>
                <Autocomplete
                  value={employeeValue}
                  onChange={(event, newValue) => {
                    setEmployeeValue(newValue);
                  }}
                  id="controllable-states-demo"
                  options={employeeList}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose Associate" InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }} />
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px 0px",
                }}
              >
                <Button
                  variant="contained"
                  className="button"
                  onClick={() => downloadReport()}
                >
                  Download report
                </Button>
                <Button
                  variant="contained"
                  className="button"
                  onClick={() => applyFilter()}
                >
                 Submit
                </Button>
              </div>
            </Box>
          </div>
        </Box>
        <Dialog
          maxWidth="md"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          fullScreen={fullScreen}
          sx={{
            margin: "10px"
          }}
          fullWidth={true}
        >
          <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingTop:'5px'}}>
          <div style={{fontSize:'20px',fontWeight:'bold',paddingLeft:'13px'}}>
            Report
          </div>
          <div
            onClick={() => setOpen(false)}
            style={{
              justifyContent: "flex-end",
              alignItems: "flex-end",
              flexDirection: "row",
              padding: "5px",
              display: "flex",
              cursor: "pointer",
              color: "black",
              fontWeight: "600",
            }}
          >
            <CloseIcon className="close-icon" />
          </div>
          </div>
          <div style={{ padding: "0px 15px",overflowY:'scroll' }}>
            {reviews.length > 0 &&
              reviews.map((value, index) => (
                <div style={{ paddingTop: "15px" }} key={index}>
                  <div
                    style={{
                      paddingBottom: "5px",
                      fontWeight: "600",
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      textTransform:'capitalize'
                    }}
                  >
                    <div>{value.projectName}</div>&nbsp;-&nbsp;{value.firstName}&nbsp;{value.lastName && value.lastName}
                  </div>
                  <div
                    style={{
                      paddingBottom: "5px",
                      fontWeight: "600",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <div style={{display:'flex',alignItems:'center'}}>
                      {moment(value.timestamp).format("MMM-YYYY")} &nbsp;<div style={{fontSize:'13px',fontWeight:'normal'}}>
                      (Feedback Submitted On: {moment(value.createTimestamp).format("DD-MMM-YYYY")})
                    </div>
                    </div>
                    

                    <div style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}>Overall Rating: {value.rating ? value.rating : "N/A"} <StarIcon style={{ color: "#FFDF00" }} /></div>
                  </div>
                  <table>
                    <tbody>
                      {value &&
                        value.review &&
                        value.review.map((data, index) => (
                          <tr key={index}>
                            <td className="qns-td">{data.question}</td>
                            <td className="ans-td">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection:'column',
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div style={{
                                  display: "flex",
                                  flexDirection:'row',
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}>
                                  {data.answer}
                                  <StarIcon style={{ color: "#FFDF00" }} />
                                </div>
                                <div>
                                  {data.note}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div style={{ padding: "10px 0px", fontSize: "14px", borderBottom: "1px solid #E8E8E8" }}>Comment: {value.comment ? value.comment : "N/A"}</div>
                </div>
              ))}
            {reviews.length === 0 && (
              <div className="report-img">
                {" "}
                <img src={require("../../assets/Images/notFound.gif")} alt=""/>
              </div>
            )}
          </div>
        </Dialog>
        <Loader open={showLoader} />
      </Grid>
    </Grid>
  );
}
