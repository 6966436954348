import React, { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const EmployeeChart = (props) => {

  useEffect(() => {
    const root = am5.Root.new("chartdiv");
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        wheelY: "zoomX",
        layout: root.verticalLayout,
        maxTooltipDistance: 0,
      })
    );

    let data = props.chartData;
    data = data.filter(function (item) {
      return item.date !== null;
    });

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 1,
        max: 5,
        extraTooltipPrecision: 1,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "month", count: 1 },
        gridIntervals: [{ timeUnit: "month", count: 1 }],
        renderer: am5xy.AxisRendererX.new(root, { rotation: 0 }),
      })
    );
    xAxis.get("dateFormats")["day"] = "dd";
    xAxis.get("periodChangeDateFormats")["month"] = "MMM";

    function createSeries(name, field) {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          valueXField: "date",
          tooltip: am5.Tooltip.new(root, {}),
          connect: true,
        })
      );

      series.bullets.push(() => {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 5,
            fill: series.get("fill"),
          }),
        });
      });

      series.strokes.template.set("strokeWidth", 2);

      series.get("tooltip").label.set("text", "[bold]{name}[/]:\n {valueY}");
      series.data.setAll(data);
    }

    createSeries("Rating", "value");

    // Add cursor
    chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
      })
    );
    chart.get("cursor").lineX.set("visible", false);
    chart.get("cursor").lineY.set("visible", false);

    return () => {
      root.dispose();
    };
  }, []); 

  return (
    <div
      id="chartdiv"
      style={{
        width: "100%",
        height: "400px",
        fontSize: "20px",
      }}
    ></div>
  );
};

export default EmployeeChart;
