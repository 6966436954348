/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import instance from "../../Utils/api";
import * as yup from "yup";
import { Formik } from "formik";
import {
  Autocomplete,
  Dialog,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CreateEmployeeClient from "./create-employee-client";
import Loader from "../../Components/Loader";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

export default function CreateProject({ ...props }) {
  const [showLoader, setShowLoader] = useState(false);
  const [getClientsList, setClientList] = useState([]);
  const [getEmployeeList, setEmployeeList] = useState([]);
  const [modalValue, setModalValue] = useState({
    label: "",
    userType: "",
  });
  const [clientSelectedValue, setClientSelectedValue] = useState([]);
  const [employeeSelectedValue, setEmployeeSelectedValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const getClients = async () => {
    await instance({
      method: "GET",
      url: `user/getEmployees?role=2`,
      validateStatus: () => true,
    }).then((data) => {
      if (data.status === "SUCCESS!") {
        setClientList(data.result);
        var list = [];
        if (data.result.length > 0) {
          data.result.map(async (item) => {
            list.push({
              label:
                item.firstName + " " + (item.lastName ? item.lastName : ""),
              value: item._id,
            });
            if (list.length > 0) {
              return setClientList(list);
            }
          });
        }
      } else {
        toast.error(data.error.message || "API Error");
      }
    });
  };
  const handleClose = (value) => {
    setOpen(false);
  };

  const getEmployees = async () => {
    await instance({
      method: "GET",
      url: `user/getEmployees?role=3`,
      validateStatus: () => true,
    }).then((data) => {
      if (data.status === "SUCCESS!") {
        setShowLoader(false);
        var list = [];
        if (data.result.length > 0) {
          data.result.map(async (item) => {
            list.push({
              label:
                (item.id ? item.id + " -" : "") +
                " " +
                item.firstName +
                " " +
                (item.lastName ? item.lastName : ""),
              value: item._id,
            });
            if (list.length > 0) {
              setEmployeeList(list);
            }
          });
        }
      } else {
        toast.error(data.error.message || "API Error");
        setShowLoader(false);
      }
    });
  };

  useEffect(() => {
    getClients();
    getEmployees();
    if (
      props.modalValue === "edit" &&
      getEmployeeList.length > 0 &&
      props.editProjectValues &&
      props.editProjectValues.members
    ) {
      const uniqueSelectedValues = new Set();
      props.editProjectValues.members.forEach((id) => {
        getEmployeeList.forEach((option) => {
          if (option.value === id) {
            uniqueSelectedValues.add(option);
          }
        });
      });
      setEmployeeSelectedValue(Array.from(uniqueSelectedValues));
    }
  }, [getEmployeeList.length]);

  useEffect(() => {
    if (
      props.modalValue === "edit" &&
      getClientsList.length > 0 &&
      props.editProjectValues &&
      props.editProjectValues.client
    ) {
      getClientsList.forEach(function (option) {
        if (option.value === props.editProjectValues.client) {
          return setClientSelectedValue(option);
        }
      });
    }
  }, [getClientsList.length]);

  const createProject = async (values) => {
    if (clientSelectedValue.length === 0) {
      setErrorMsg("This field is required");
    } else {
      setShowLoader(true);
      let data = {};
      data.title = values.title;
      if (values.description) {
        data.description = values.description;
      }
      if (clientSelectedValue) {
        data.client = clientSelectedValue.value;
      }
      if (employeeSelectedValue) {
        let selectedValues = [];
        employeeSelectedValue.forEach((item) => {
          selectedValues.push(item.value);
        });
        data.members = selectedValues;
      }
      if (values.status) {
        data.status = values.status;
      }
      data.timePeriod = values.timePeriod;
      let url =
        props.modalValue === "edit" ? `/${props.editProjectValues._id}` : "";
      await instance({
        method: props.modalValue === "edit" ? "PUT" : "POST",
        url: `project${url}`,
        data,
      }).then((data) => {
        if (data.status === "SUCCESS!") {
          setShowLoader(false);
          props.closeModal();
          if (props.modalValue !== "edit") {
            window.location.href = "/dashboard";
          } else {
            props.getProjects();
          }
          toast.success(
            props.modalValue === "edit"
              ? "Updated Successfully"
              : "Created Successfully"
          );
        } else {
          setShowLoader(false);
          toast.error(data.error.message);
        }
      });
    }
  };
  return (
    <div className="pro-container">
      <Paper className="project-paper">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: '2px solid #C8C8C8',
            borderRadius: '4px',
            padding: "10px",
          }}
        >
          <div className="dialog-header">
            {props.modalValue === "create" ? "Create Project" : "Edit Project"}
          </div>
          <div className="close-btn" onClick={() => props.closeModal()}>
            <CloseIcon className="close-icon" />
          </div>
        </div>
      </Paper>
      <div className="create-pro">
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: props.editProjectValues ? props.editProjectValues.title : "",
            description: props.editProjectValues
              ? props.editProjectValues.description
              : "",
            status:
              props.editProjectValues && props.editProjectValues.status
                ? props.editProjectValues.status
                : "Active",
            timePeriod:
              props.editProjectValues && props.editProjectValues.timePeriod
                ? props.editProjectValues.timePeriod
                : "Monthly",
          }}
          onSubmit={createProject}
          validationSchema={yup.object({
            title: yup.string().required("This field is required"),
            status: yup.string().required("This field is required"),
            timePeriod: yup.string().required("This field is required"),
          })}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit} >
              <TextField
                fullWidth
                id="title"
                name="title"
                label="Enter Project Name *"
                margin="normal"
                size="small"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
                inputProps={{
                  style: {
                    height: "34px",
                    fontSize: "14px",
                  },
                }}
              />
              <TextField
                fullWidth
                id="description"
                name="description"
                label="Enter Project Description"
                margin="normal"
                size="small"
                value={formik.values.description}
                onChange={formik.handleChange}
                InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
                inputProps={{
                  style: {
                    height: "34px",
                    fontSize: "14px",
                  },
                }}
              />
              <div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px 0px",
                }}
              >
                <div
                  className="pointer"
                  onClick={() => {
                    setOpen(true);
                    setModalValue({
                      label: "Create Customer",
                      userType: "Customer",
                    });
                  }}
                >
                  <PersonAddIcon style={{ color: "#008080" }} />
                </div>
              </div>

              <Autocomplete
                name="customer"
                value={clientSelectedValue}
                onChange={(event, newValue) => {
                  setClientSelectedValue(newValue);
                  setErrorMsg("");
                }}
                id="controllable-states-demo"
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                options={getClientsList}
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Customer *"
                    placeholder="select or search"
                    InputLabelProps={{
                      style: { fontSize: 14, color: "#575757" },
                    }}
                  />
                )}
              />
              <div className="error-msg">{errorMsg}</div>
              <div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px 0px",
                  marginTop: "5px",
                }}
              >
                <div
                  className="pointer"
                  onClick={() => {
                    setOpen(true);
                    setModalValue({
                      label: "Create Associate",
                      userType: "Associate",
                    });
                  }}
                >
                  <PersonAddIcon style={{ color: "#008080" }} />
                </div>
              </div>
              <Autocomplete
                multiple
                id="tags-standard"
                options={getEmployeeList}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) => option.label || ""}
                value={employeeSelectedValue}
                onChange={(event, newValue) => {
                  setEmployeeSelectedValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Associate"
                    placeholder="select or search"
                    InputLabelProps={{
                      style: { fontSize: 14, color: "#575757" },
                    }}
                  />
                )}
              />
              <FormControl fullWidth style={{ marginTop: "15px" }}>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ fontSize: 14, color: "#575757" }}
                >
                  Status
                </InputLabel>
                <Select
                  name="status"
                  style={{ width: "100%" }}
                  id="status"
                  value={formik.values.status}
                  label="Status"
                  onChange={formik.handleChange}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                >
                  <MenuItem defaultValue={"Active"} value="Active">
                    Active
                  </MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
                <FormHelperText
                  style={{ color: "#D32F2F", margin: "4px 14px 0px" }}
                >
                  {formik.touched.status && formik.errors.status}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth style={{ marginTop: "15px" }}>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ fontSize: 14, color: "#575757" }}
                >
                  Cycle
                </InputLabel>
                <Select
                  name="timePeriod"
                  style={{ width: "100%" }}
                  id="timePeriod"
                  value={formik.values.timePeriod}
                  label="Cycle"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.timePeriod &&
                    Boolean(formik.errors.timePeriod)
                  }
                >
                  <MenuItem defaultValue={"Monthly"} value="Monthly">
                    Monthly
                  </MenuItem>
                  <MenuItem value="Weekly">Weekly</MenuItem>
                </Select>
                <FormHelperText
                  style={{ color: "#D32F2F", margin: "4px 14px 0px" }}
                >
                  {formik.touched.timePeriod && formik.errors.timePeriod}
                </FormHelperText>
              </FormControl>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="button pro-btn"
                type="submit"
              >
                {props.modalValue === "edit" ? "Update" : "Create"}
              </Button>
            </form>
          )}
        </Formik>
      </div>
      <Dialog open={open} maxWidth="xs" fullWidth={true}>
        <CreateEmployeeClient
          closeModal={handleClose}
          modalValue={modalValue}
          getEmployees={getEmployees}
          getclients={getClients}
        />
      </Dialog>
      <Loader open={showLoader} />
    </div>
  );
}
