/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  TextField,
  Typography,
  Hidden,
  Dialog,
  Paper,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import instance from "../Utils/api";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ViewFeedback from "./Admin/viewFeedback";
import Loader from "../Components/Loader";
import AssociateFeedback from "./Client/AssociateFeedback";
import { ChevronLeft } from "@mui/icons-material";
// import ViewListIcon from "@mui/icons-material/ViewList";
import ForumIcon from "@mui/icons-material/Forum";
import GroupsIcon from "@mui/icons-material/Groups";
import moment from "moment";

export default function Members() {
  const [projectMembers, setProjectMembers] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [projects, setProjects] = useState([]);
  const [searchData, setSearchData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [filterView, setFilterView] = useState(false);
  const [FilterData, setFilterData] = useState();
  const [employeeId, setEmployeeId] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const id = useParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [ratingTime, setRatingTime] = useState("");
  const [userRating, setUserRating] = useState("");
  const role = localStorage.getItem("role");
  const [feedBackDailog, setFeedbackDialog] = useState(false);
  const [timePeriod, setTimePeriod] = useState(null);
  const [viewfeedbackDialog, setViewFeedbackDialog] = useState(false);
  const [submissions, setSubmissions] = useState([]);

  const getProjectMembers = async () => {
    setShowLoader(true);
    await instance({
      method: "GET",
      url: `project/members/${id.id}`,
      validateStatus: () => true,
    }).then((resp) => {
      if (resp.status === "SUCCESS!") {
        setShowLoader(false);
        setProjectMembers(resp.result.users);
        setProjects(resp.result.project);
        setRatingTime(resp.result.project.isRating);
        setSearchData(resp.result.users);
        setFilterData(resp.result);
        setTimePeriod(resp.result.project.timePeriod);
        setSubmissions(resp.result.submissions);
        // if (role === "1") {
        //   setFilterView(true);
        //   setEmployeeId(resp.result.users[0]._id);
        //   setEmployeeName(resp.result.users[0].firstName);
        //   if (selectedItem.includes(resp.result.users[0]._id)) {
        //     setSelectedItem(selectedItem.filter((a) => a !== resp.result.users[0]._id));
        //     setFilterView(false);
        //   } else {
        //     setSelectedItem([resp.result.users[0]._id]);
        //   }
        // }
      } else {
        setShowLoader(false);
      }
    });
  };

  useEffect(() => {
    getProjectMembers();
  }, []);

  const SearchList = (e) => {
    setFilterView(false);
    setSearchValue(e);
    const original = searchData;
    if (e.length > 0) {
      const filterdata = original.filter((item) => {
        const query = e.toLowerCase();
        return item.firstName && item.firstName.toLowerCase().includes(query);
      });
      setProjectMembers(filterdata);
    } else {
      setProjectMembers(original);
    }
  };

  const closeSearch = () => {
    setSearchValue("");
    setProjectMembers(searchData);
    setSelectedItem([]);
  };

  const navToFilter = (id, firstName, lastName, rating, index) => {
    setFilterView(true);
    setViewFeedbackDialog(true);
    setUserRating(rating);
    setEmployeeId(id);
    if (lastName) {
      setEmployeeName(`${firstName} ${lastName}`);
    } else {
      setEmployeeName(firstName);
    }
    if (selectedItem.includes(id)) {
      setSelectedItem(selectedItem.filter((a) => a !== id));
      setFilterView(false);
    } else {
      setSelectedItem([id]);
    }
  };

  const handleFeedback = () => {
    // if (!ratingTime) {
    setFeedbackDialog(true);
    setFilterView(false);
    // }
  };
  return (
    <Box>
      <Box component="main" sx={{ height: "100%" }}>
        <Paper style={{ height: "40px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              background: "#F5F5F5",
              fontWeight: "600",
              justifyContent: "space-between",
              height: "100%",
              paddingRight: "10px",
            }}
          >
            <div className="header-container">
              <div
                onClick={() => {
                  window.location.href = "/dashboard";
                }}
                className="pointer pro-title"
              >
                <ChevronLeft style={{ color: "#2986CE" }} />
                <div style={{ fontSize: "12px", color: "#2986CE" }}>Back</div>
              </div>
              <div className="pro-title">{projects && projects.title}</div>
              {role !== "1" && (
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        background: "rgb(0, 128, 128)",
                      },
                    },
                  }}
                  title={
                    <Typography fontSize={14}>
                      Click here to{" "}
                      {projects.isRating === false ? "give" : "view"} your Team
                      Feedback
                    </Typography>
                  }
                >
                  <div
                    style={{
                      color: "#2986CE",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      handleFeedback();
                    }}
                  >
                    <GroupsIcon style={{ width: "30px" }} />
                    <p style={{ fontSize: "12px" }}>
                      {" "}
                      {projects.isRating === false
                        ? "Click here to give your Team Feedback"
                        : "Click here to view your Team Feedback"}
                    </p>
                  </div>
                </Tooltip>
              )}
            </div>
          </Box>
        </Paper>
        <Box sx={{ display: "flex",}}>
          <Grid
            item
            xs={12}
            sm={12}
            // md={role === "1" ? 4 : 12}
            md={12}
            component={Paper}
            elevation={1}
            square
            className="calc-ht"
            sx={{overflow:"scroll"}}
          >
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                id="search-bar"
                className="text"
                onInput={(e) => {
                  SearchList(e.target.value);
                }}
                label="Search"
                variant="outlined"
                placeholder="Search..."
                size="small"
                value={searchValue}
                style={{ width: "100%", margin: "10px 0px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={() => closeSearch()}
                      style={{ cursor: "pointer" }}
                      position="start"
                    >
                      <CloseIcon className="close-icon" />
                    </InputAdornment>
                  ),
                  style: {
                    padding: "0px",
                  },
                }}
              />
              {role === "1" && (
                <div>
                  {projectMembers.length > 0 ? (
                    projectMembers.map((value, index) => (
                      <List key={index}>
                        <ListItem
                          className="pointer"
                          key={index}
                          onClick={() =>
                            navToFilter(
                              value._id,
                              value.firstName,
                              value.lastName,
                              value.isRating,
                              index
                            )
                          }
                          disablePadding
                          secondaryAction={
                            <IconButton edge="end" aria-label="delete">
                              <KeyboardArrowRightIcon />
                            </IconButton>
                          }
                          style={{
                            backgroundColor: selectedItem.includes(value._id)
                              ? "#D6E8F7"
                              : "",
                            color: selectedItem.includes(value._id)
                              ? "black"
                              : "",
                          }}
                        >
                          <div>
                            <ListItemText>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                }}
                                component="span"
                              >
                                {value.firstName}{" "}
                                {value.lastName && value.lastName}
                              </Typography>
                            </ListItemText>
                            <ListItemText style={{ fontSize: "12px" }}>
                              <Typography
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {value.email}
                              </Typography>
                            </ListItemText>
                            <ListItemText style={{ fontSize: "12px" }}>
                              <Typography
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                Employee Id: {value.id}
                              </Typography>
                            </ListItemText>
                          </div>
                        </ListItem>
                        <Divider />
                      </List>
                    ))
                  ) : (
                    <div className="not-found-txt">
                      {!showLoader &&
                      projectMembers.length === 0 &&
                      role === "1"
                        ? "No Data Found"
                        : "Please Wait..."}
                    </div>
                  )}
                </div>
              )}
              {role !== "1" && (
                <div className="pro-details">
                  <table>
                    <thead>
                      <tr>
                        <th>Associate Name</th>
                        <th>Associate Email</th>
                        <th>Associate Feedback</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectMembers.length > 0 &&
                        projectMembers.map((value, index) => (
                          <tr
                            key={index}
                            style={{
                              backgroundColor: selectedItem.includes(value._id)
                                ? "#E6F8FC"
                                : "",
                              color: selectedItem.includes(value._id)
                                ? "black"
                                : "",
                              width: "100%",
                            }}
                          >
                            <td
                              style={{
                                width: "30%",
                                textTransform: "capitalize",
                              }}
                            >
                              {value.firstName ? value.firstName : "N/A"}&nbsp;
                              {value.lastName && value.lastName}
                            </td>
                            <td style={{ width: "30%" }}>
                              {" "}
                              {value.email ? value.email : "N/A"}
                            </td>
                            <td style={{ width: "40%" }}>
                              <div style={{ width: "100%", display:"flex", flexDirection:"row",alignItems:'center'}}>
                                <ForumIcon style={{ width: "20px", color:"rgb(0, 128, 128)" }} />
                              
                               <div
                                  className="edit-btn pointer view-btn"
                                  
                                  onClick={(e) => {
                                    navToFilter(
                                      value._id,
                                      value.firstName,
                                      value.lastName,
                                      value.isRating,
                                      index
                                    );
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  Click here to{" "}
                                  {value.isRating === true ? "view" : "give"}{" "}
                                  your Feedback
                                </div>
                              
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <div
                    style={{
                      margin: "50px auto",
                      width: "100%",
                    }}
                  >
                    {/* <p
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: 'bold',
                      }}
                    >
                      Recent 3 months rating
                    </p> */}
                    <table
                      style={{ tableLayout: "auto", width: "100%" }}
                      border="1"
                    >
                      <thead>
                        <tr>
                          <th style={{ paddingLeft: "10px" }}>
                            Feedback History
                          </th>
                          {submissions.dates &&
                            submissions.dates.map((timeFormat, index) => (
                              <th
                                key={index}
                                style={{
                                  width: "25%",
                                  textAlign: "center",
                                }}
                              >
                                {moment(Number(timeFormat)).format("MMM-YY")}
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                        {submissions.data &&
                          submissions.data.map((item, index) => (
                            <tr key={index}>
                              <td style={{ paddingLeft: "10px" }}>
                                {item.userName}
                              </td>
                              {submissions.dates.map((timeStamp, timeIndex) => {
                                const matchingRating = item.ratings.find(
                                  (rating) => rating.date === timeStamp
                                );
                                return (
                                  <td
                                    key={timeIndex}
                                    style={{
                                      width: `${
                                        70 / submissions.dates.length
                                      }%`,
                                      textAlign: "center",
                                    }}
                                  >
                                    {matchingRating
                                      ? matchingRating.rating
                                      : "NA"}
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {projectMembers.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="not-found-txt"></div>
                </div>
              )}
            </Box>
          </Grid>
          <Hidden smDown>
            {filterView && (
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                component={Paper}
                elevation={1}
                square
                className="calc-ht"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {filterView && (
                    <ViewFeedback
                      projectName={projects.title}
                      open={viewfeedbackDialog}
                      employeeName={employeeName}
                      employeeId={employeeId}
                      userRating={userRating}
                      data={FilterData}
                      ratingTime={ratingTime}
                      timePeriod={timePeriod}
                      getProjectMembers={getProjectMembers}
                      close={() => {
                        setFilterView(false);
                        setSelectedItem([]);
                      }}
                    />
                  )}
                </Box>
              </Grid>
            )}
          </Hidden>
          <Hidden smUp mdUp>
            <Dialog
              sx={{
                margin: "10px",
              }}
              open={filterView}
              maxWidth="sm"
              fullWidth={true}
              fullScreen={fullScreen}
            >
              {filterView && (
                <div>
                  <ViewFeedback
                    projectName={projects.title}
                    open={viewfeedbackDialog}
                    employeeId={employeeId}
                    userRating={userRating}
                    data={FilterData}
                    employeeName={employeeName}
                    ratingTime={ratingTime}
                    getProjectMembers={getProjectMembers}
                    timePeriod={timePeriod}
                    close={() => {
                      setFilterView(false);
                      setSelectedItem([]);
                    }}
                  />
                </div>
              )}
            </Dialog>
          </Hidden>
        </Box>
      </Box>
      <Loader open={showLoader} />
      <AssociateFeedback
        getData={getProjectMembers}
        open={feedBackDailog}
        timePeriod={timePeriod}
        close={() => setFeedbackDialog(false)}
        projectId={id.id}
      />
    </Box>
  );
}
