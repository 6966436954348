/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import {
  EmojiEmotions,
  PeopleAlt,
  School,
  Star,
  EmojiEvents,
  Stars,
  SignLanguage,
} from "@mui/icons-material";
import instance from "../../Utils/api";
import Loader from "../../Components/Loader";
import feedbackQuestionsData from "../../Utils/feedbackQuestionsData";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

export default function AssociateFeedback({ ...props }) {
  const [showLoader, setShowLoader] = useState(false);
  const [projectMembers, setProjectMembers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [dateFormat, setDateFormat] = useState("");
  const [isDataPresent, setIsDataPresent] = useState(false);
  const [toastCount, setToastCount] = useState(false);
  const timePeriod = props.timePeriod;

  const badgeData = [
    { title: "Top Performer", icon: <Star /> },
    { title: "Leadership", icon: <School /> },
    { title: "Above & Beyond", icon: <EmojiEvents /> },
    { title: "Team Player", icon: <PeopleAlt /> },
    { title: "Customer Hero", icon: <Stars /> },
    { title: "Rockstar", icon: <EmojiEmotions /> },
    { title: "High Five", icon: <SignLanguage /> },
  ];

  const feedBack = (questionIndex, answer) => {
    setProjectMembers((prevFeedbackData) => {
      const newData = [...prevFeedbackData];
      newData[currentIndex].feedback[questionIndex].answer = answer;
      newData[currentIndex].feedback[questionIndex].note =
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "";
      for (let i = 0; i < newData.length; i++) {
        if (i !== currentIndex) {
          if (newData[i].feedback[questionIndex].answer === 0) {
            newData[i].feedback[questionIndex].answer = answer;
            newData[i].feedback[questionIndex].note =
              answer === 1
                ? "Worst"
                : answer === 2
                ? "Bad"
                : answer === 3
                ? "Not great"
                : "";
          }
        }
      }
      return newData;
    });
  };

  const addNewVariable = (data) => {
    return data.map((item) => ({
      ...item,
      isCallback: false,
      comment: "",
      badge: "Select a badge",
      feedback: [...feedbackQuestionsData.map((q) => ({ ...q, answer: 0 }))],
    }));
  };

  const handleCommentChange = (e) => {
    const newComment = e.target.value;
    const newProjectMembers = [...projectMembers];
    newProjectMembers[currentIndex].comment = newComment;
    if (currentIndex === 0) {
      for (let i = 1; i < newProjectMembers.length; i++) {
        newProjectMembers[i].comment = newComment;
      }
    }
    setProjectMembers(newProjectMembers);
  };

  const handleCheckChange = (e) => {
    setProjectMembers((prevFeedbackData) => {
      const newData = [...prevFeedbackData];
      newData[currentIndex].isCallback = e.target.checked;
      if (currentIndex === 0) {
        for (let i = 1; i < newData.length; i++) {
          newData[i].isCallback = e.target.checked;
        }
      }
      return newData;
    });
  };
  const handleSelectChange = (e) => {
    setProjectMembers((prevFeedbackData) => {
      const newData = [...prevFeedbackData];
      newData[currentIndex].badge = e.target.value;
      if (currentIndex === 0) {
        for (let i = 1; i < newData.length; i++) {
          newData[i].badge = e.target.value;
        }
      }
      return newData;
    });
  };

  const getProjectMembers = async () => {
    setShowLoader(true);
    await instance({
      method: "GET",
      url: `project/members/${props.projectId}`,
      validateStatus: () => true,
    }).then(async (resp) => {
      if (resp.status === "SUCCESS!") {
        setShowLoader(false);
        setProjectData(resp.result.project);
        if (resp.result.project && resp.result.project.isRating) {
          await instance({
            method: "GET",
            url: `submission/getFeedback?isTeam=true&project=${props.projectId}`,
            validateStatus: () => true,
          }).then((resp) => {
            if (resp.status === "SUCCESS!") {
              if (resp.result.length > 0) {
                let data = resp.result.map((feedBack) => {
                  return {
                    firstName:
                      feedBack.firstName && feedBack.firstName[0]
                        ? feedBack.firstName[0]
                        : "Team Feedback",
                    lastName:
                      feedBack.lastName && feedBack.lastName[0]
                        ? feedBack.lastName[0]
                        : "",
                    isCallback: feedBack.isCallback,
                    comment: feedBack.comment || "",
                    badge: feedBack.badge || "Select a badge",
                    feedback: feedBack.review,
                  };
                });
                setProjectMembers(data);
                setIsDataPresent(true);
              }
            } else {
              setShowLoader(false);
              setIsDataPresent(false);
            }
          });
        } else {
          if (resp.result.users.length > 0) {
            const newData = addNewVariable(resp.result.users);
            const team = [
              {
                firstName: "Team Feedback",
                isCallback: false,
                comment: "",
                badge: "Select a badge",
                feedback: [
                  ...feedbackQuestionsData.map((q) => ({ ...q, answer: 0 })),
                ],
              },
            ];
            setProjectMembers([...team, ...newData]);
          }
        }
      } else {
        setShowLoader(false);
      }
    });
  };

  useEffect(() => {
    getProjectMembers();
  }, []);

  useEffect(() => {
    timeStamp(timePeriod);
  }, [timePeriod]);

  const handleNext = (value) => {
    //if (projectMembers[currentIndex].feedback.every((obj) => obj.answer > 0)) {
      saveFeedbackData(
        projectMembers[currentIndex]._id,
        projectMembers[currentIndex].feedback
      );
      setCurrentIndex((prevIndex) =>
        prevIndex + 1 < projectMembers.length ? prevIndex + 1 : prevIndex
      );
      if (currentIndex === 0 && !isDataPresent && !toastCount) {
        toast.info(
          "For your convenience we have copied the Team feedback to the individual Associate. But you can change the ratings as per your wish.",
          {
            autoClose: 8000,
            pauseOnHover: true,
            hideProgressBar: false,
          }
        );
        setToastCount(true);
      }
      if (value === "submit") {
        setConfirmDialog(true);
      }
    //} 
    // else {
    //   toast.error("Please give ratings for each of the fields.");
    // }
  };

  const handlePrevious = () => {
    saveFeedbackData(
      projectMembers[currentIndex]._id,
      projectMembers[currentIndex].feedback
    );
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const saveFeedbackData = (userId, feedback) => {};

  const handleConfirm = async () => {
    let data = {};
    data.data = projectMembers.map(
      ({ feedback, comment, badge, _id, isCallback, ...rest }) => {
        const extractedObject = {
          review: feedback.map(({ note, ...rest }) => {
            if (note !== "") {
              return { ...rest, note };
            }
            return rest;
          }),
        };
        if (_id) {
          extractedObject.user = _id;
        }
        extractedObject.client = projectData.client;
        extractedObject.project = props.projectId;
        if (comment) {
          extractedObject.comment = comment;
        }
        if (badge !== ("Select a badge" || "")) {
          extractedObject.badge = badge;
        }
        extractedObject.isCallback = isCallback;
        console.log(extractedObject);
        return extractedObject;
      }
    );
    setShowLoader(true);
    await instance({
      method: "POST",
      url: "submission/submitFeedback",
      data,
    }).then((data) => {
      if (data.status === "SUCCESS!") {
        setShowLoader(false);
        toast.success("Your Feedback is submitted");
        setConfirmDialog(false);
        getProjectMembers();
        props.getData();
        props.close();
        setIsDataPresent(true);
      } else {
        setShowLoader(false);
        toast.error(data.error || "API Error");
        setIsDataPresent(false);
      }
    });
  };

  const downloadReport = async () => {
    setShowLoader(true);
    let urlParams = `/downloadReport?isPrevious=true&project=${props.projectId}`;
    await instance({
      method: "GET",
      url: `submission${urlParams}`,
      validateStatus: () => true,
    }).then((data) => {
      // console.log(data,"data in then")
      if (data.status === "SUCCESS!") {
        const downloadLink = document.createElement("a");
        downloadLink.href = data.result[0];
        downloadLink.download = `${data.fileName}`;
        downloadLink.click();
        downloadLink.remove();
        setShowLoader(false);
        toast.success("DownLoaded Successfully");
      } else {
        setShowLoader(false);
        toast.error(data.error.message || "API Error");
      }
    });
  };

  const handlenote = (e, questionIndex) => {
    setProjectMembers((prevFeedbackData) => {
      const newData = [...prevFeedbackData];
      newData[currentIndex].feedback[questionIndex].note = e.target.value;
      return newData;
    });
  };

  const timeStamp = (timePeriod) => {
    if (timePeriod === "Monthly") {
      const monthStart = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("MMMM (DD-");
      const monthEnd = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("DD)");
      setDateFormat(`${monthStart}${monthEnd}`);
    } else {
      const currentDay = moment().day();
      const startDay = moment()
        .subtract(currentDay + 6, "days")
        .format("(MMM DD - ");
      const endDay = moment()
        .subtract(currentDay + 2, "days")
        .format("MMM DD)");
      setDateFormat(`${startDay}${endDay}`);
    }
  };

  return (
    <Dialog
      open={props.open}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle
        id="scroll-dialog-title"
        style={{ padding: "10px", fontSize: "16px" }}
      >
        <div className="close-btn" style={{ padding: "0px" }}>
          <CloseIcon
            onClick={() => {
              props.close();
              getProjectMembers();
            }}
            className="close-icon"
          />
        </div>
        <div className="team-title">
          {projectMembers.length > 0 && projectMembers[currentIndex].firstName}{" "}
          {projectMembers.length > 0 &&
            projectMembers[currentIndex].lastName &&
            projectMembers[currentIndex].lastName}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {timePeriod} - {dateFormat}
        </div>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{ padding: "0px", fontSize: "14px" }}
      >
        {projectMembers.length > 0 &&
          projectMembers[currentIndex].feedback.map((value, questionIndex) => (
            <div
              key={questionIndex}
              style={{
                borderBottom: "1px solid #C8C8C8",
                padding: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontWeight: "500",
                  alignItems: "center",
                }}
              >
                {value.question}
                {/* <div style={{ color: "red", fontSize: "15px" }}> *</div> */}

                <Tooltip
                  arrow
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        // background: "rgb(0, 128, 128)",
                        width: "100%",
                        maxWidth: "500px",
                      },
                    },
                  }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {value.info.map((value, index) => (
                        <div key={index}>{value}</div>
                      ))}
                    </div>
                  }
                >
                  <InfoIcon style={{ color: "#A9A9A9", cursor: "pointer" }} />
                </Tooltip>
              </div>

              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                {[...Array(5)].map((star, index) => {
                  index += 1;
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        !isDataPresent && feedBack(questionIndex, index);
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                        }}
                        index={index}
                      >
                        {!isDataPresent ? (
                          <Tooltip title="change ratings if required">
                            {index <= value.answer ? (
                              <StarIcon style={{ color: "#FFDF00" }} />
                            ) : (
                              <StarBorderIcon style={{ color: "#D3D3D3" }} />
                            )}
                          </Tooltip>
                        ) : (
                          <>
                            {index <= value.answer ? (
                              <StarIcon style={{ color: "#FFDF00" }} />
                            ) : (
                              <StarBorderIcon style={{ color: "#D3D3D3" }} />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div>
                {value.answer < 4 && value.answer !== 0 && (
                  <TextField
                    fullWidth
                    id="note"
                    name="note"
                    label="Type your message here"
                    margin="normal"
                    size="small"
                    rows={5}
                    InputLabelProps={{
                      style: { fontSize: 14 },
                      shrink: true,
                    }}
                    onChange={(e) => {
                      handlenote(e, questionIndex);
                    }}
                    value={value.note}
                    inputProps={{
                      style: {
                        fontSize: "14px",
                      },
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        <div style={{ padding: "10px 16px", width: "100%" }}>
          <InputLabel
            id="demo-simple-select-label"
            style={{ fontSize: 14, color: "#575757", padding: "5px 0px" }}
          >
            Badge:
          </InputLabel>

          <Select
            name="badge"
            id="badge"
            disabled={isDataPresent}
            autoWidth={false}
            value={
              projectMembers.length > 0 && projectMembers[currentIndex].badge
            }
            onChange={(e) => handleSelectChange(e)}
            style={{
              fontSize: "14px",
              height: "40px",
              width: "100%",
            }}
          >
            <MenuItem value="Select a badge" defaultValue={"Select a badge"}>
              Select a badge
            </MenuItem>
            {badgeData.map((item, index) => (
              <MenuItem value={item.title} key={index}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontSize: "14px",
                  }}
                >
                  <span
                    style={{
                      color: "#008080",
                      marginRight: "5px",
                      fontSize: 18,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.icon}
                  </span>
                  <>{item.title}</>
                </div>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div
          style={{
            padding: "5px 7px",
            display: "flex",
            alignItems: "center",
            fontWeight: "500",
            fontSize: "14px",
            letterSpacing: "0.8px",
          }}
        >
          <input
            style={{ width: "15px", height: "25px", margin: "0px 10px" }}
            type="checkbox"
            checked={
              projectMembers.length > 0 &&
              projectMembers[currentIndex].isCallback
            }
            disabled={isDataPresent}
            onChange={(e) => handleCheckChange(e)}
          />
          <label>Request call back form the management to discuss more.</label>
        </div>
        <div style={{ margin: "12px 0px", padding: "0px 16px" }}>
          <TextField
            fullWidth
            id="comment"
            name="comment"
            label="Areas for Improvement & Additional Comments"
            margin="normal"
            size="small"
            multiline
            rows={5}
            disabled={isDataPresent}
            value={
              projectMembers.length > 0 && projectMembers[currentIndex].comment
            }
            onChange={(e) => {
              handleCommentChange(e);
            }}
            InputLabelProps={{
              style: { fontSize: 14 },
              shrink: true,
            }}
            inputProps={{
              style: {
                fontSize: "14px",
              },
            }}
          />
        </div>
      </DialogContent>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 10px",
        }}
      >
        <Button
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          className="button"
          onClick={handlePrevious}
          disabled={currentIndex === 0}
        >
          Previous
        </Button>
        {/* {currentIndex === projectMembers.length - 1 && projectData.isRating === true &&  */}
        <Button
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          className="button"
          onClick={() => handleNext("next")}
          disabled={currentIndex === projectMembers.length - 1}
        >
          Next
        </Button>
        {/* } */}
        {currentIndex === projectMembers.length - 1 &&
          projectData.isRating === false && (
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              className="button"
              onClick={() => handleNext("submit")}
            >
              Submit
            </Button>
          )}
        {currentIndex === projectMembers.length - 1 &&
          projectData.isRating === true && (
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              className="button"
              onClick={() => downloadReport()}
            >
              Download Report
            </Button>
          )}
      </div>
      <Dialog open={confirmDialog}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to submit the Feedback for the Team as well as
            Team members?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmDialog(false);
            }}
          >
            No
          </Button>
          <Button onClick={() => handleConfirm()}>Yes</Button>
        </DialogActions>
        <Loader open={showLoader} />
      </Dialog>
      <Loader open={showLoader} />
    </Dialog>
  );
}
