import * as React from "react";

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Avatar,
  Tooltip,
  MenuItem,
  CssBaseline,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import instance from "../Utils/api";

const pages = [
  {
    path: "/dashboard",
    label: "Projects",
    navigateTo: "/dashboard",
  },
  {
    path: "/employees",
    label: "Associates",
    navigateTo: "/employees",
    role: "1",
  },
  {
    path: "/clients",
    label: "Customers",
    navigateTo: "/clients",
    role: "1",
  },
  {
    path: "/reports",
    label: "Reports",
    navigateTo: "/reports",
    role: "1",
  },
  {
    path: "/charts",
    label: "Charts",
    navigateTo: "/charts",
    role: "1",
  }
];
const settings = [
  {
    path: "/profile",
    label: "Profile",
    navigateTo: "/profile",
  },
  {
    path: "/changePassword",
    label: "ChangePassword",
    navigateTo: "/changePassword",
  },
  {
    path: "/logout",
    label: "Logout",
  },
];
export default function Header() {
  const [anchorElUser, setAnchorElUser] = useState();
  const [activeMenu, setActiveMenu] = useState("/dashboard");
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigation = (value) => {
    setActiveMenu(value.path);
    if (value.path === "/logout") {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      window.location.href = "/";

    } else {
      navigate(value.navigateTo);
      handleCloseUserMenu();
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    setActiveMenu(path);
  }, [activeMenu]);

  const [userData, setUserData] = useState([]);
  const getUserData = async () => {
    await instance({
      method: "GET",
      url: "user"
    })
      .then((data) => {
        if (data.status === "SUCCESS!") {
          setUserData(data.result);
        } else {
        }
      });
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar style={{ background: "#008080", padding: "5px 0px" }}>
        <Toolbar>
          <div className="image">
            <div style={{ height: 40, width: 40 }}>
              <img
                className="logo" alt=""
                src={require("../assets/Images/edvenswa-logo.png")}
              />
            </div>
          </div>
          <Typography
            variant="h6"
            noWrap
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "flex" },
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              flexGrow: 1,
            }}
          >
            &nbsp;Feedback
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            {pages.map((item, index) => (
              <div key={index}>
                {(item.role === role || item.role === undefined) &&
                  <Typography
                    key={index}
                    sx={{ color: "#fff", mr: 5, cursor: "pointer" }}
                    textAlign="center"
                    onClick={() => handleNavigation(item)}
                    className={activeMenu === item.path ? "borderbtn" : ""}
                  >
                    {item.label}
                  </Typography>}
              </div>

            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar className="avatar">
                  <div style={{ textTransform: "uppercase" }}>
                    {userData &&
                      userData.firstName &&
                      userData.firstName.slice(0, 1)}
                    {userData &&
                      userData.lastName &&
                      userData.lastName.slice(0, 1)}
                  </div>
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {pages.map((item, index) => (
                <div key={index}>
                  {(item.role === role || item.role === undefined) &&
                    <MenuItem onClick={() => { handleNavigation(item); handleCloseUserMenu() }}
                      key={index}
                      sx={{ display: { xs: "flex", sm: "none", cursor: "pointer" } }}
                    >

                      <Typography
                        textAlign="center"
                        className={activeMenu === item.path ? "borderclr" : ""}
                      >
                        {item.label}
                      </Typography>
                    </MenuItem>}
                </div>
              ))}
              {settings.map((setting, index) => (
                <MenuItem key={index} onClick={() => { handleNavigation(setting); handleCloseUserMenu() }} sx={{ display: { cursor: "pointer" } }}>
                  <Typography key={index}
                    textAlign="center"
                    className={activeMenu === setting.path ? "borderclr" : ""}
                  >
                    {setting.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
