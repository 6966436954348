import React from "react";
import { Box, Grid, Paper, } from "@mui/material";
import Projects from "./Projects";
const myStyle = {
  box: {
    display: "flex",
    flexDirection: "row",
    background: "#F5F5F5",
    padding: "10px 10px 10px 5px",
    fontWeight: "800",
    justifyContent: "space-between",
  }
};
export default function Home() {
  return (
    <Box className="main">
      <Grid container className="mg-auto"
        item md={11} xs={12} sm={12}
      >
        <Paper style={{ height: "40px", width: "100%" }}>
          <Box style={myStyle.box}
          >
            Projects
          </Box>
        </Paper>
        <Paper style={{ width: "100%" }}>
          <Projects />
        </Paper>
      </Grid>
    </Box>
  );
}
