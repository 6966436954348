/* eslint-disable react-hooks/exhaustive-deps */
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { toast } from "react-toastify";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {
  CloseOutlined,
  Star,
  School,
  EmojiEmotions,
  PeopleAlt,
  EmojiEvents ,
  Stars,
  SignLanguage,
  OpenInFullOutlined
} from "@mui/icons-material";
import moment from "moment";
import instance from "../../Utils/api";
import Filter from "../Admin/Filter";
import Loader from "../../Components/Loader";

export default function ViewFeedback({ ...props }) {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [feebackData, setFeedBackData] = useState([]);
  const [comment, setComment] = useState("");
  const [callback, setCallBack] = useState(false);
  const [rating, setRating] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(true);
  const [badge, setBadge] = useState("Select a badge");
  const [isBadgeAdded, setIsBadgeAdded] = useState(false);
  const role = localStorage.getItem("role");
  const timePeriod = props.timePeriod;
  const [dateFormat, setDateFormat] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const handleChange = (event, newValue) => {
    setSelectedIndex(newValue);
  };
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };
  const answer = 0;
  const feedbackQuestionsData = [
    {
      question: "Technical Expertise",
      answer: 0,
      info: [
        "How would you rate the technical expertise of our associate?",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3 && "Not great",
    },
    {
      question: "Communication Skills",
      answer: 0,
      info: [
        "How would you rate the communication skills of our associate?",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "",
    },

    {
      question: "Responsiveness & Professionalism",
      answer: 0,
      info: [
        "How responsive is our associate to your needs and queries?",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "",
    },
    {
      question: "Understanding Your Business Needs",
      answer: 0,
      info: [
        "How well did our associate understand and address your business needs?",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "",
    },
    {
      question: "Quality of Work",
      answer: 0,
      info: [
        "How would you rate the quality of the work delivered by our associate?",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "",
    },
    {
      question: "Innovation & Problem-Solving Skills",
      answer: 0,
      info: [
        "Did the associate offer innovative solutions or technologies?",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "",
    },
    {
      question: "Overall Satisfaction",
      answer: 0,
      info: [
        "How would you rate your overall experience working with the associate",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "",
    },
    // {
    //   question: "Security & Compliance",
    //   answer: 0,
    //   info: [
    //     "Data Security: Were there any breaches or data mishandling incidents?",
    //     "Regulatory Compliance: Did the project comply with industry standards and regulations?",
    //   ],
    //   note:
    //     answer === 1
    //       ? "Worst"
    //       : answer === 2
    //       ? "Bad"
    //       : answer === 3
    //       ? "Not great"
    //       : "",
    // },
    // {
    //   question: "Innovation & Problem-Solving",
    //   answer: 0,
    //   info: [
    //     "Creative Solutions: Did the offshore team offer innovative solutions or technologies?",
    //     "Continuous Improvement: Was there evidence that the provider made improvements based on feedback?",
    //   ],
    //   note:
    //     answer === 1
    //       ? "Worst"
    //       : answer === 2
    //       ? "Bad"
    //       : answer === 3
    //       ? "Not great"
    //       : "",
    // },
    // {
    //   question: "Post-Deployment Support",
    //   answer: 0,
    //   info: [
    //     "Proactiveness: Did the team anticipate potential challenges and address them proactively?",
    //     "Responsiveness: How quickly were post-deployment issues addressed?",
    //     "Quality: How effective were the solutions provided post-deployment?",
    //   ],
    //   note:
    //     answer === 1
    //       ? "Worst"
    //       : answer === 2
    //       ? "Bad"
    //       : answer === 3
    //       ? "Not great"
    //       : "",
    // },
    // {
    //   question: "Cultural & Operational Fit",
    //   answer: 0,
    //   info: [
    //     "Cultural Compatibility: Were there any cultural misunderstandings or challenges?",
    //     "Operational Alignment: Did the offshore team's work processes align with the client's?",
    //   ],
    //   note:
    //     answer === 1
    //       ? "Worst"
    //       : answer === 2
    //       ? "Bad"
    //       : answer === 3
    //       ? "Not great"
    //       : "",
    // },
    // {
    //   question: "Overall Satisfaction",
    //   answer: 0,
    //   info: [
    //     "Would you recommend us to others?",
    //     "Would you consider working with us on future projects?",
    //   ],
    //   note:
    //     answer === 1
    //       ? "Worst"
    //       : answer === 2
    //       ? "Bad"
    //       : answer === 3
    //       ? "Not great"
    //       : "",
    // },
  ];

  const badgeData = [
    { title: "Top Performer", icon: <Star /> },
    { title: "Leadership", icon: <School /> },
    { title: "Above & Beyond", icon: <EmojiEvents />},
    { title: "Team Player", icon: <PeopleAlt /> },
    {title:'Customer Hero',icon:<Stars />},
    {title:'Rockstar',icon:<EmojiEmotions />},
    {title:'High Five',icon:<SignLanguage />}
  ];

  const timeStamp = (timePeriod) => {
    if (timePeriod === "Monthly") {
      const monthStart = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("MMMM (DD-");
      const monthEnd = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("DD)");
      setDateFormat(`${monthStart}${monthEnd}`);
    } else {
      const currentDay = moment().day();
      const startDay = moment()
        .subtract(currentDay + 6, "days")
        .format("(MMM DD - ");
      const endDay = moment()
        .subtract(currentDay + 2, "days")
        .format("MMM DD)");
      setDateFormat(`${startDay}${endDay}`);
    }
  };

  const getFilterFeedbackData = async () => {
    setShowLoader(true);
    await instance({
      method: "GET",
      url: `submission/getFeedback?project=${props.data.project._id}&user=${props.employeeId}`,
      validateStatus: () => true,
    }).then((data) => {
      if (data.status === "SUCCESS!") {
        setShowLoader(false);
        if (data.result.length > 0) {
          setFeedBackData(data.result[0].review);
          setRating(false);
          setIsSubmitted(true);
          setComment(data.result[0].comment);
          setCallBack(data.result[0].isCallback);
          setBadge(data.result[0] && data.result[0].badge);
          if (data.result[0] && data.result[0].badge) {
            setIsBadgeAdded(true);
          }
        } else {
          setFeedBackData(feedbackQuestionsData);
          setRating(true);
          setIsSubmitted(false);
          setIsBadgeAdded(true);
        }
      } else {
        setShowLoader(false);
        toast.error(data.error.message || "API Error!");
      }
    });
  };

  useEffect(() => {
    getFilterFeedbackData();
    setSelectedIndex(1);
    setComment("");
    setCallBack(false);
    setBadge("Select a badge");
    timeStamp(timePeriod);
  }, [props.employeeId]);

  const feedBack = (questionIndex, answer) => {
    setFeedBackData((prevFeedbackData) => {
      const newData = [...prevFeedbackData];
      newData[questionIndex].answer = answer;
      newData[questionIndex].note =
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "";
      return newData;
    });
  };

  const handlenote = (e, questionIndex) => {
    setFeedBackData((prevFeedbackData) => {
      const newData = [...prevFeedbackData];
      newData[questionIndex].note = e.target.value;
      return newData;
    });
  };

  const submitFeedback = async () => {
    //if (feebackData.every((obj) => obj.answer > 0)) {
      let data = {};
      data.client = props.data.project.client;
      data.project = props.data.project._id;
      data.user = props.employeeId;
      if (comment) {
        data.comment = comment;
      }
      if (callback) {
        data.isCallback = callback;
      }
      if (badge !== ("Select a badge" || "")) {
        data.badge = badge;
      }
      data.review = feebackData.map(({ note, ...rest }) => {
        if (note !== "") {
          return { ...rest, note };
        }
        return rest;
      });
      setShowLoader(true);
      await instance({
        method: "POST",
        url: "submission",
        data: data,
      })
        .then((data) => {
          if (data.status === "SUCCESS!") {
            setShowLoader(false);
            setRating(false);
            setIsSubmitted(true);
            setIsBadgeAdded(true);
            props.getProjectMembers();
            toast.success("Your Feedback is submitted");
          } else {
            setShowLoader(false);
            toast.error("API Error");
          }
        })
        .catch((error) => {
          console.log(error, "error");
          setShowLoader(false);
          setIsSubmitted(false);
          setIsBadgeAdded(false);
          toast.error(data.error || "API Error");
        });
    //} 
    // else {
    //   toast.error("Please give ratings for each of the fields.");
    // }
  };

  return (
    <Box sx={{
      width:'100%'
    }} >
      <Paper elevation={1} className={` ${isFullscreen ? 'fullscreen-header' : 'vf-header'}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <div className="employee-name" style={{ padding: "0px" }}>
            {isFullscreen && `${props.projectName} - `}{props.employeeName}
          </div>
          {selectedIndex === 1 && (
            <div>
              {timePeriod} - {dateFormat}
            </div>
          )}  
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            {!isFullscreen && <div style={{paddingRight:'10px'}}><OpenInFullOutlined style={{ color: "#b1a0a0",cursor:'pointer',width:'30px' }} onClick={toggleFullscreen}/></div>}

            <div onClick={() => props.close()} className="pointer">
              <CloseOutlined style={{ color: "#b1a0a0",paddingLeft:'10px',width:'30px' }} />
            </div>
          </div>
        </div>
        <Box>
          <Tabs
            sx={{ minHeight: "35px" }}
            TabIndicatorProps={{
              sx: {
                bgcolor: "orange",
                height: "0px",
              },
            }}
            value={selectedIndex}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab value={1} label="Latest" className="tab-txt" />
            <Tab value={2} label="Previous" className="tab-txt" />
          </Tabs>
        </Box>
      </Paper>
      <Box 
      // sx={{ mx: 0 }} 
      sx={{width: isFullscreen ? '100vw' : '100%',
      height:isFullscreen ? '100vh' : '100%',
      position: isFullscreen ? 'fixed' : 'relative', 
      top: isFullscreen ? '70px' : 'auto',
      bottom:isFullscreen ? '0':'auto',
      left: isFullscreen ? '0' : 'auto',
      zIndex: isFullscreen ? '2000' : 'auto', 
      transition: 'width 0.3s', 
      backgroundColor:'white'}}>
        {selectedIndex === 1 && (
          <div>
            {selectedIndex === 1 && (
              <div style={{...(!isFullscreen ? {height: '400px',overflowY: 'scroll'} : {}), ...(isFullscreen
                ? {
                    position: 'relative',
                    top: '30px',
                    overflowY: 'scroll',
                    height: '490px'
                  }
                : {}),}}>
                {feebackData.length > 0 &&
                  feebackData.map((value, questionIndex) => (
                    <div
                      key={questionIndex}
                      style={{
                        borderBottom: "1px solid #C8C8C8",
                        padding: "10px",
                      }}
                    >
                      <div style={{ display: "flex", fontSize: "14px" }}>
                        {value.question}
                        {/* <div style={{ color: "red", fontSize: "15px" }}> *</div> */}

                        <Tooltip
                          arrow
                          placement="top"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                width: "100%",
                                maxWidth: "500px",
                              },
                            },
                          }}
                          title={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {value.info.map((value, index) => (
                                <div key={index}>{value}</div>
                              ))}
                            </div>
                          }
                        >
                          <InfoIcon
                            style={{ color: "#A9A9A9", cursor: "pointer" }}
                          />
                        </Tooltip>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                        }}
                      >
                        {[...Array(5)].map((star, index) => {
                          index += 1;
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                !props.userRating && role !== '1' &&
                                  feedBack(questionIndex, index);
                              }}
                            >
                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                                index={index}
                              >
                                {index <= value.answer ? (
                                  <StarIcon style={{ color: "#FFDF00" }} />
                                ) : (
                                  <StarBorderIcon
                                    style={{ color: "#D3D3D3" }}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        {value.answer < 4 && value.answer !== 0 && (
                          <TextField
                            fullWidth
                            id="comment"
                            name="comment"
                            label="Type your message here"
                            margin="normal"
                            size="small"
                            rows={5}
                            disabled={role === "1" ? true : isSubmitted}
                            InputLabelProps={{
                              style: { fontSize: 14 },
                              shrink: true,
                            }}
                            onChange={(e) => {
                              handlenote(e, questionIndex);
                            }}
                            value={value.note}
                            inputProps={{
                              style: {
                                fontSize: "14px",
                              },
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                {selectedIndex === 1 && isBadgeAdded && (
                  <div style={{ padding: "15px 10px" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{
                        fontSize: 14,
                        color: "#575757",
                        paddingBottom: "5px",
                      }}
                    >
                      Badge:
                    </InputLabel>

                    <Select
                      name="badge"
                      value={badge}
                      disabled={role === "1" ? true : isSubmitted}
                      onChange={(e) => setBadge(e.target.value)}
                      style={{
                        fontSize: "14px",
                        height: "40px",
                        width: "100%",
                      }}
                    >
                      <MenuItem
                        value="Select a badge"
                        defaultValue={"Select a badge"}
                      >
                        Select a badge
                      </MenuItem>
                      {badgeData.map((item, index) => (<MenuItem value={item.title} key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent:'flex-start',
                              fontSize: "14px",
                            }}
                          >
                            <span
                              style={{
                                color: "#008080",
                                marginRight: "5px",
                                fontSize: 18,
                                display: "flex",
                              alignItems: "center",
                              }}
                            >
                              {item.icon}
                            </span>
                            <>{item.title}</>
                          </div>
                        </MenuItem>)
                      )}
                    </Select>
                  </div>
                )}
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "500",
                    fontSize: "14px",
                    letterSpacing: "0.8px",
                  }}
                >
                  <input
                    style={{
                      width: "15px",
                      height: "25px",
                      margin: "0px 5px 0px 0px",
                    }}
                    disabled={role === "1" ? true : isSubmitted}
                    type="checkbox"
                    checked={callback}
                    onChange={(e) => setCallBack(e.target.checked)}
                  />
                  <label>Request call back form the management to discuss more.</label>
                  {/* <Checkbox checked={callback} onChange={(e) => setCallBack(e.target.checked)} label="Call back the Associate" /> */}
                  {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                </div>
                {selectedIndex === 1 && (
                  <div style={{ padding: "10px" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ fontSize: 14, color: "#575757" }}
                    >
                      Areas for Improvement & Additional Comments
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="comment"
                      name="comment"
                      label="Areas for Improvement & Additional Comments"
                      margin="normal"
                      size="small"
                      multiline
                      rows={5}
                      disabled={role === "1" ? true : isSubmitted}
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      InputLabelProps={{
                        style: { fontSize: 14 },
                        shrink: true,
                      }}
                      inputProps={{
                        style: {
                          fontSize: "14px",
                        },
                      }}
                    />
                  </div>
                )}
                {!props.userRating && rating && role !=="1" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      className="button"
                      onClick={() => submitFeedback()}
                    >
                      Submit
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <Loader open={showLoader} />
        {selectedIndex === 2 && (
          <Filter
            employeeId={props.employeeId}
            projectId={props.data.project._id}
            isFullscreen={isFullscreen}
          />
        )}
      </Box>
    </Box>
  );
}
