import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import instance from '../../Utils/api';
import Loader from '../../Components/Loader';
import { toast } from 'react-toastify';


export default function UpdateProfile(props) {
    const [showLoader, setShowLoader] = useState(false);
    const [userData, setUserData] = useState([]);

    const ProfileSchema = Yup.object({
        firstName: Yup.string().required("This field is required"),
        email: Yup.string()
            .email("Invalid email")
            .required("This field is required"),
    });

    const editProfile = async (value) => {
        setShowLoader(true);
        const data = {};
        data.firstName = value.firstName;
        if(value.lastName === ""){
          data.lastName = " ";
        }
        else {
            data.lastName = value.lastName.trim()
        }
        data.email = value.email;
        await instance({
            method: "PUT",
            url: "user/updateProfile",
            data
        })
            .then((data) => {
                if (data.status === "SUCCESS!") {
                    setShowLoader(false);
                    getUserData();
                    toast.success("Updated Successfully")
                } else {
                    setShowLoader(false);
                    toast.error(data.error.message || "API Error")
                }
            });
    }
   
    const getUserData = async () => {
        setShowLoader(true);
        await instance({
            method: "GET",
            url: "user"
        })
            .then((data) => {
                if (data.status === "SUCCESS!") {
                    setShowLoader(false);
                    setUserData(data.result);
                } else {
                    setShowLoader(false);
                }
            });
    };

    useEffect(() => {
        getUserData();
    }, []);


    return (
            <Grid container component="main">
                <Grid item xs={12} sm={12} md={12}>
                    <Box
                        sx={{
                            my: 4,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >

                        <div className='profile'>
                            {userData &&
                                userData.firstName &&
                                userData.firstName.slice(0, 1)}
                                {userData &&
                                userData.lastName &&
                                userData.lastName.slice(0, 1)}
                        </div>
                        <div>{userData && userData.firstName}</div>
                        <Box sx={{ mt: 1, maxWidth: "430px" }}>
                            <Formik enableReinitialize={true}
                                initialValues={{
                                    firstName: userData && (userData.firstName || ""),
                                    lastName: userData && (userData.lastName || ""),
                                    email: userData && (userData.email || ""),
                                }}
                                onSubmit={editProfile}
                                validationSchema={ProfileSchema}
                            >
                                {(formik) => (
                                    <form onSubmit={formik.handleSubmit}>
                                        <TextField
                                            fullWidth
                                            id="firstName"
                                            name="firstName"
                                            label="Enter First Name"
                                            margin="normal"
                                            size="small"
                                            variant="outlined"
                                            value={formik.values.firstName}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.firstName &&
                                                Boolean(formik.errors.firstName)
                                            }
                                            helperText={
                                                formik.touched.firstName && formik.errors.firstName
                                            }
                                            InputLabelProps={{ style: { fontSize: 14,color:"#575757" }, shrink: true }}
                                            inputProps={{
                                                style: {
                                                    height: "34px",
                                                    fontSize: "14px",
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            id="lastName"
                                            name="lastName"
                                            label="Enter Last Name"
                                            margin="normal"
                                            size="small"
                                            variant="outlined"
                                            value={formik.values.lastName}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.lastName &&
                                                Boolean(formik.errors.lastName)
                                            }
                                            helperText={
                                                formik.touched.lastName && formik.errors.lastName
                                            }
                                            InputLabelProps={{ style: { fontSize: 14,color:"#575757" }, shrink: true }}
                                            inputProps={{
                                                style: {
                                                    height: "34px",
                                                    fontSize: "14px",
                                                },
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            label="Enter Email"
                                            margin="normal"
                                            size="small"
                                            variant="outlined"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.email && Boolean(formik.errors.email)
                                            }
                                            helperText={formik.touched.email && formik.errors.email}
                                            InputLabelProps={{ style: { fontSize: 14,color:"#575757" }, shrink: true }}
                                            inputProps={{
                                                style: {
                                                    height: "34px",
                                                    fontSize: "14px",
                                                },
                                            }}
                                        />


                                        <Button
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            className="button"
                                            type="submit"
                                        >
                                            Update
                                        </Button>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Grid>
                <Loader open={showLoader}/>
            </Grid>
    )
}