import React, { useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import instance from "../../Utils/api";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Link,
  Box,
  Typography,
  FormHelperText,
} from "@mui/material";
import FormDialog from "../../Components/Dialog";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components/Loader";
import ReCAPTCHA from "react-google-recaptcha";
import { siteKey } from "../../Utils/recaptcha";


export default function SignUp() {
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const captchaRef = useRef(null);
  const validationSchema = Yup.object({
    firstName: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("This field is required"),
    password: Yup.string()
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/g,
        "For a stronger password, include at least one uppercase letter, one lowercase letter, one number, and one special character."
      )
      .required("This field is required"),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "Password & Confirm Password should be same"
      )
      .required("This field is required"),
    recaptcha: Yup.string().required('Please verify you are human'),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const createAccount = async (value) => {
    const data = {};
    data.firstName = value.firstName;
    data.lastName = value.lastName;
    data.email = value.email;
    data.password = value.password;
    data["g-recaptcha-response"] = captchaRef.current.getValue();
    setShowLoader(true);
    await instance({
      method: "POST",
      url: "user/signUp",
      data,
    }).then((response) => {
      let obj = {};
      obj = response;
      if (obj.status === "SUCCESS!") {
        setShowLoader(false);
        setOpen(true);
        //navigate("/login");
      } else {
        toast.error(response.error.message || "API Error");
        setShowLoader(false);
        captchaRef.current.reset();
      }
    });
  };
 
  return (
    <Box style={{ height: "100vh", overflowY: "auto" }}>
      <Grid
        container style={{height:"100%"}}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "430px",
              margin: "20px auto",
              padding: "0px 32px",
            }}
          >
            <img height={60} width={60}
              alt=""
              src={require("../../assets/Images/edvenswa-logo.png")}
            />
            <Typography
              component="h1"
              variant="h5"
              sx={{ padding: "0px 0px 16px 0px", color: "#008080", marginBottom: "20px" }}
            >
              Feedback
            </Typography>
            <Typography
              component="div"
              variant="div"
              className="login-txt"
            >
              Create your account
            </Typography>
            <Box sx={{ mt: 1, width: "100%" }}>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                  recaptcha: "",
                }}
                onSubmit={createAccount}
                validationSchema={validationSchema}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      fullWidth
                      id="firstName"
                      name="firstName"
                      label="Enter First Name"
                      margin="normal"
                      size="small"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      autoFocus
                      InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
                      inputProps={{
                        style: {
                          height: "34px",
                          fontSize: "14px",
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      id="lastName"
                      name="lastName"
                      label="Enter Last Name"
                      margin="normal"
                      size="small"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
                      inputProps={{
                        style: {
                          height: "34px",
                          fontSize: "14px",
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Enter Email"
                      margin="normal"
                      size="small"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
                      inputProps={{
                        style: {
                          height: "34px",
                          fontSize: "14px",
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      id="password"
                      name="password"
                      label="Enter Password"
                      type="password"
                      margin="normal"
                      size="small"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
                      inputProps={{
                        style: {
                          height: "34px",
                          fontSize: "14px",
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Enter Confirm Password"
                      type="password"
                      margin="normal"
                      size="small"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                      InputLabelProps={{ style: { fontSize: 14, color: "#575757" } }}
                      inputProps={{
                        style: {
                          height: "34px",
                          fontSize: "14px",
                        },
                      }}
                    />
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px 0px", flexDirection: "column" }}>
                      <ReCAPTCHA theme="light" name="recaptcha" onChange={(value) => formik.setFieldValue('recaptcha', value)} sitekey={siteKey} ref={captchaRef} />
                      <FormHelperText style={{ color: "#D32F2F",margin:"4px 14px 0px" }}>{formik.touched.recaptcha &&
                        formik.errors.recaptcha}</FormHelperText>
                    </div>

                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      className="button"
                      type="submit"
                    >
                      Sign Up
                    </Button>
                  </form>
                )}
              </Formik>
            </Box>
            <div style={{ fontSize: "13px", padding: "8px 0px" }}>
              Already have an account?{" "}
              <Link
                href="/"
                variant="body2"
                className="signup-txt"
                sx={{
                  color: "#006161",
                  textDecoration: "none",
                }}
              >
                {"Login"}
              </Link>
            </div>
          </Box> 
        </Grid>
        <Loader open={showLoader} />
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={true}
          closeOnClick
          theme="light"
        />
        <FormDialog
          message="Thanks! your account has been successfully created."
          closePopUp={handleClose}
          showPopUp={open}
          route="/"
        />
      </Grid>
    </Box>
  );
}
