import axios from 'axios';
//  const URL = 'http://localhost:4000/api/'; //Local
// const URL = 'http://52.32.169.202:4000/api/'; //Dev
const URL = 'https://feedback-api.edvenswa.com/api/'; //Prod

const instance = axios.create({
    baseURL: URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Authorization",
    },
});
instance.defaults.headers.common['Authorization'] = localStorage.getItem('token') || "";
instance.interceptors.response.use((response) => {
    if (response.status === 401) {
        window.location.href = '/';
    } 
    else 
    return response.data;
}, function (error) {
    return error.response.data;
});

export default instance;