import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from 'react-router-dom';

export default function FormDialog({ ...props }) {
  const navigate = useNavigate();

  const closePopUp = () => {
    props.closePopUp();
    navigate(props.route);
  }

  return (
    <div>
      <Dialog open={props.showPopUp}>
        <DialogContent>
          <DialogContentText>
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closePopUp()}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}