import React from 'react'
import Clients from './clients-list'

export default function Employees() {
  return (
    <div>
      <Clients/>
    </div>
  )
}
