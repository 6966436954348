
import { initializeApp } from "firebase/app";
import {getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCIxlrgbAlDgXbwngyYFEfUyE-rZq6Tgnc",
  authDomain: "feedback-c6f3e.firebaseapp.com",
  projectId: "feedback-c6f3e",
  storageBucket: "feedback-c6f3e.appspot.com",
  messagingSenderId: "818735485957",
  appId: "1:818735485957:web:9ef0c9dcf2a7b09b50b86c",
  measurementId: "G-22YM6G54GP"
};
initializeApp(firebaseConfig);

const auth = getAuth();
export default auth;