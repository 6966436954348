const answer=0;
const feedbackQuestionsData = [
    {
      question: "Overall Satisfaction",
      answer: 0,
      info: [
        "How satisfied are you with the overall performance of the Offshore Team?",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3 && "Not great",
    },
    {
      question: "Project Understanding",
      answer: 0,
      info: [
        "How well did our Offshore team understand the project requirements and goals?",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "",
    },

    {
      question: "Project Management",
      answer: 0,
      info: [
        "How effectively did our Offshore team manage the project timelines and deliverables?",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "",
    },
    {
      question: "Collaboration",
      answer: 0,
      info: [
        "How well did our Offshore team collaborate with your team and other stakeholders?",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "",
    },
    {
      question: "Flexibility and Adaptability ",
      answer: 0,
      info: [
        "How flexible and adaptable was our Offshore team in responding to changes and unexpected challenges?",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "",
    },
    {
      question: "Documentation and Reporting",
      answer: 0,
      info: [
        "How would you rate the quality and completeness of project documentation and reporting provided by our Offshore team?",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "",
    },
    {
      question: "Customer Satisfaction",
      answer: 0,
      info: [
        "How satisfied are you with the outcomes and deliverables of the project?",
      ],
      note:
        answer === 1
          ? "Worst"
          : answer === 2
          ? "Bad"
          : answer === 3
          ? "Not great"
          : "",
    },
    // {
    //   question: "Security & Compliance",
    //   answer: 0,
    //   info: [
    //     "Data Security: Were there any breaches or data mishandling incidents?",
    //     "Regulatory Compliance: Did the project comply with industry standards and regulations?",
    //   ],
    //   note:
    //     answer === 1
    //       ? "Worst"
    //       : answer === 2
    //       ? "Bad"
    //       : answer === 3
    //       ? "Not great"
    //       : "",
    // },
    // {
    //   question: "Innovation & Problem-Solving",
    //   answer: 0,
    //   info: [
    //     "Creative Solutions: Did the offshore team offer innovative solutions or technologies?",
    //     "Continuous Improvement: Was there evidence that the provider made improvements based on feedback?",
    //   ],
    //   note:
    //     answer === 1
    //       ? "Worst"
    //       : answer === 2
    //       ? "Bad"
    //       : answer === 3
    //       ? "Not great"
    //       : "",
    // },
    // {
    //   question: "Post-Deployment Support",
    //   answer: 0,
    //   info: [
    //     "Proactiveness: Did the team anticipate potential challenges and address them proactively?",
    //     "Responsiveness: How quickly were post-deployment issues addressed?",
    //     "Quality: How effective were the solutions provided post-deployment?",
    //   ],
    //   note:
    //     answer === 1
    //       ? "Worst"
    //       : answer === 2
    //       ? "Bad"
    //       : answer === 3
    //       ? "Not great"
    //       : "",
    // },
    // {
    //   question: "Cultural & Operational Fit",
    //   answer: 0,
    //   info: [
    //     "Cultural Compatibility: Were there any cultural misunderstandings or challenges?",
    //     "Operational Alignment: Did the offshore team's work processes align with the client's?",
    //   ],
    //   note:
    //     answer === 1
    //       ? "Worst"
    //       : answer === 2
    //       ? "Bad"
    //       : answer === 3
    //       ? "Not great"
    //       : "",
    // },
    // {
    //   question: "Overall Satisfaction",
    //   answer: answer,
    //   info: [
    //     "Would you recommend us to others?",
    //     "Would you consider working with us on future projects?",
    //   ],
    //   note:
    //     answer === 1
    //       ? "Worst"
    //       : answer === 2
    //       ? "Bad"
    //       : answer === 3
    //       ? "Not great"
    //       : "",
    // },
  ];
  export  default feedbackQuestionsData;